import React, { useEffect, useState } from 'react';
import logger from '../logger';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import ScriptScene from '../components/scenes/scene';
import { KeyVisualId, ObjectProperty } from '@try-saga/storybuilder-iso';
import { useDispatch } from 'react-redux';
import { addNewKeyVisual, clearScenes, deleteKeyVisual, getScene } from '../redux/reducers/scenes';
import { getStory } from '../redux/reducers/story';
import { clearState } from '../redux/reducers/app';
import { Button } from '../components/lib';
import axios from '../config/api/axiosConfig';
import { toast } from 'react-toastify';

const Scenes = () => {
  const scenes = useSelector((state: RootState) => state.scenes?.scenes);
  const story = useSelector((state: RootState) => state.story);
  const user = useSelector((state: RootState) => state.user);
  const [isDownload, setIsDownload] = useState<boolean | undefined>(undefined);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
    if (id) {
      dispatch(getScene({ id }));
      dispatch(getStory({ id }));
    }
    return () => {
      dispatch(clearScenes());
    };
  }, [id, dispatch]);

  const handleAddVisual = (index: number, sceneIndex: number) => {
    dispatch(addNewKeyVisual({ index, sceneIndex, user }));
  };

  const handleDeleteVisual = (index: number, sceneIndex: number, id?: KeyVisualId) => {
    if (scenes[sceneIndex]?.keyVisuals && index >= 0 && index < (scenes[sceneIndex].keyVisuals?.length || 0)) {
      dispatch(deleteKeyVisual({ index, id, sceneIndex }));
    } else {
      logger.error('Index out of bounds');
    }
  };

  const downloadAllImages = async () => {
    if (isDownload) {
      return;
    }

    try {
      setIsDownload(true);
      const response = await axios.get(`/scene/downloadAllImageOfStory/${story.id}`);
      if (response) {
        toast.info(response.data.message);
      } else {
        logger.error('Failed to download images');
        toast.info(`Failed to download images! Please check with admin.`);
      }
      setIsDownload(undefined);
    } catch (error) {
      logger.error(error);
      setIsDownload(undefined);
    }
  };

  return (
    <div className="mx-[70px] pl-[5px] pr-[16px] flex flex-col gap-y-[13px]">
      <div className="text-center mt-[10px]">
        <h3 className="text-[32px] leading-[40px]">{story.name}</h3>
        <span className="leading-[15px] text-[12px]">
          by: {user.first_name} {user.last_name}
        </span>
      </div>
      {scenes.length ? (
        <div className="flex flex-col gap-y-[20px]">
          {scenes?.map((scene, index) => {
            return (
              <ScriptScene
                key={scene.id}
                {...scene}
                sceneIndex={index}
                properties={scene.properties as Record<string, ObjectProperty>}
                handleDeleteVisual={handleDeleteVisual}
                handleAddVisual={handleAddVisual}
              />
            );
          })}

          <Button
            loading={isDownload}
            text="Download all images"
            action={downloadAllImages}
            relative
            className="rounded-[8px] gap-x-[14px] w-full font-[400] text-[#FFFDF8] py-[7px] py-[5px]"
            color="blue"
            flex
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default Scenes;
