import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfirmBox {
  showModal?: boolean;
  isConfirmed?: boolean;
  toLocation?: string;
}
interface AppState {
  loading: boolean;
  error: string | null;
  success: boolean | null;
  message: string | null;
}

const initialState: AppState & ConfirmBox = {
  loading: false,
  error: null,
  success: false,
  message: null,
  showModal: false,
  isConfirmed: false,
  toLocation: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.success = null;
      state.error = null;
      state.message = null;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setSuccess: (state, action: PayloadAction<string | undefined>) => {
      state.success = true;
      state.loading = false;
      state.error = null;
      if (action.payload) {
        state.message = action.payload;
      }
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.error = action.payload;
      }
      state.success = false;
      state.loading = false;
      state.message = null;
    },
    clearState: (state) => {
      state.loading = false;
      state.success = null;
      state.error = null;
      state.message = null;
    },
    setToLocation: (state, action: PayloadAction<string>) => {
      state.toLocation = action.payload;
    },
    openConfirmPopup: (state) => {
      state.showModal = true;
    },
    setIsConfirmed: (state) => {
      state.isConfirmed = true;
      state.showModal = false;
      state.toLocation = '';
    },
    cancelConfirmPopup: (state) => {
      state.showModal = false;
      state.isConfirmed = false;
      state.toLocation = '';
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setSuccess,
  setError,
  clearState,
  setToLocation,
  openConfirmPopup,
  setIsConfirmed,
  cancelConfirmPopup,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
