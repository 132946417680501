import React from 'react';
import { EmailInputProps, FileInputProps, InputProps, PasswordInputProps, TextInputProps } from '../formInterfaces';

const InputComponent: React.FC<InputProps> = (props) => {
  switch (props.type) {
    case 'email': {
      const emailProps = props as EmailInputProps;

      return (
        <input
          id={emailProps.id}
          name={emailProps.name}
          type="email"
          placeholder={emailProps.placeholder}
          value={emailProps.value}
          onChange={emailProps.onChange}
          onBlur={emailProps.handleBlur}
          className={emailProps.className}
        />
      );
    }
    case 'password': {
      const passwordProps = props as PasswordInputProps;

      return (
        <input
          id={passwordProps.id}
          name={passwordProps.name}
          type="password"
          placeholder={passwordProps.placeholder}
          value={passwordProps.value}
          onChange={passwordProps.onChange}
          onBlur={passwordProps.handleBlur}
          className={passwordProps.className}
        />
      );
    }
    case 'text': {
      const textProps = props as TextInputProps;

      return (
        <input
          type="text"
          id={textProps.id}
          maxLength={textProps.characterLimit}
          name={textProps.name}
          value={textProps.value || ''}
          className={textProps.className}
          disabled={textProps.disabled}
          placeholder={textProps.placeholder}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => textProps.onFocus?.(e)}
          onChange={textProps.onChange}
          onBlur={textProps.handleBlur}
        />
      );
    }
    case 'textarea': {
      const textAreaProps = props as TextInputProps;

      return (
        <textarea
          id={textAreaProps.id}
          name={textAreaProps.name}
          value={textAreaProps.value}
          className={textAreaProps.className}
          maxLength={textAreaProps.characterLimit}
          placeholder={textAreaProps.placeholder}
          onFocus={(e: React.FocusEvent<HTMLTextAreaElement>) => textAreaProps.onFocus?.(e)}
          onChange={textAreaProps.onChange}
          onBlur={textAreaProps.handleBlur}
        />
      );
    }
    case 'file': {
      const fileProps = props as FileInputProps;

      return (
        <input
          multiple={fileProps.multiple}
          ref={fileProps.inputRef}
          type="file"
          className="hidden"
          accept={fileProps.accept}
          onChange={fileProps.onChange}
        />
      );
    }
  }
};

export const Input = React.memo(InputComponent);
