import React from 'react';
import { useFormContext } from '../../context/FormContext';
import { FormProps, JsonDataFormField } from './formInterfaces';

export const Form: React.FC<FormProps> = ({ onSubmit, children, className, hasFormData, reset = true, ...props }) => {
  const { form, validate, setForm } = useFormContext();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (hasFormData) {
        /* For Forms having file input */
        const data = new FormData();
        for (const [key, { value }] of Object.entries(form)) {
          if (value && key !== 'confirm_password') {
            if (Array.isArray(value)) {
              for (const i in value) {
                data.append(`${key}_${i + 1}`, value[i]);
              }
            } else {
              if (value instanceof File) {
                data.append(key, value);
              } else {
                data.append(key, value.toString());
              }
            }
          }
        }
        if (onSubmit) (onSubmit as (data: FormData) => void)(data);
      } else {
        /* For Forms having json data */
        const data: Record<string, JsonDataFormField['value']> = {};
        for (const [key, { value }] of Object.entries(form)) {
          if (value && key !== 'confirm_password' && (typeof value === 'string' || typeof value === 'number')) {
            data[key] = value;
          }
        }
        if (onSubmit) (onSubmit as (data: Record<string, JsonDataFormField['value']>) => void)(data);
      }
      if (reset) {
        setForm({});
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={className} {...props}>
      {children}
    </form>
  );
};
