import React, { useEffect, useState } from 'react';
import { TabsProps } from './tabsInterfaces';
import { Image, Tab } from '../lib';
import { useLocation } from 'react-router-dom';

const initialState = {
  headerTab: 0,
  sideTab: 0,
};

export const Tabs: React.FC<TabsProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState(initialState);

  const handleTabClick = (name: string, index: number) => {
    setActiveIndex({
      ...initialState,
      [name]: index,
    });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab')?.toLowerCase();
  useEffect(() => {
    if (!tab) return; // Early return if tab is null or empty

    const tabIndex = props.items.findIndex((item) => item.label?.toLowerCase() === tab);
    if (tabIndex !== -1) {
      setActiveIndex({ ...initialState, headerTab: tabIndex });
    }
  }, [tab, props.items]);

  return (
    <>
      <div className="flex rounded-ss-[20px] bg-[#FFFDF8] w-full h-full">
        <div className="w-full">
          <div className="flex gap-x-[25px] max-h-[30px] w-full mt-[31px] pt-0 pr-[9px] pb-0 pl-[6px] max-w-[647px] ml-[33px] mb-[24.5px]">
            {props.items.map((item, index) => {
              return (
                <div key={item.label}>
                  <div
                    className="flex cursor-pointer flex-col max-w-[160px] w-full"
                    onClick={() => handleTabClick('headerTab', index)}
                  >
                    <div className="flex cursor-pointer justify-center items-center text-center gap-x-3">
                      {item.icon && (
                        <div className="h-[30px] w-[20px] flex items-center ">
                          <Image src={item.icon} alt="icon" className="object-cover" />
                        </div>
                      )}
                      <span className="font-semibold text-[14px] tracking-[2px] text-black font-poppins">
                        {item.label}
                      </span>
                    </div>
                    {activeIndex.headerTab === index && (
                      <hr className="w-full bg-[#000000] rounded-[10px] h-[2px]"></hr>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {Array.isArray(props.items[activeIndex.headerTab].component) ? (
            <Tab
              component={(props.items[activeIndex.headerTab].component as React.ComponentType[])[activeIndex.sideTab]}
            />
          ) : (
            <Tab component={props.items[activeIndex.headerTab].component as React.ComponentType} />
          )}
        </div>
      </div>
    </>
  );
};
