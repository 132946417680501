import React from 'react';
import { Image } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';

const ImagePlaceholder = () => {
  return (
    <div
      style={{
        background: 'linear-gradient(105.03deg, rgba(255, 255, 255, 0.08) -3.2%, rgba(255, 255, 255, 0.0752) 100.8%)',
      }}
      className="w-[418px] h-[238px] rounded-[10px] p-[1px]"
    >
      <div className="w-[418px] h-[238px] relative rounded-lg overflow-hidden bg-white">
        <div className="absolute inset-0 backdrop-blur-[180px]">
          <Image
            src={getCdnUrl('images/noise.png')}
            alt="noise"
            className="absolute inset-0 w-full h-full object-cover mix-blend-soft-light"
          />
          <div
            className="absolute w-[333px] h-[268px] top-[2px] left-[55px] animate-all
              rotate-[55deg] bg-[rgb(92,157,255)] mix-blend-soft-light 
              blur-2xl transform-gpu"
          />
          <div
            className="absolute w-[333px] h-[268px] top-[46px] left-[456px] 
              rotate-[-59deg] bg-[rgb(92,235,255)] mix-blend-soft-light animate-all
              blur-2xl transform-gpu"
          />
          <div
            className="absolute w-[345px] h-[266px] top-[156px] left-[36px] 
              bg-[rgb(217,108,255)] mix-blend-soft-light animate-all
              blur-2xl transform-gpu"
          />
          <div
            className="absolute w-[281px] h-[217px] top-[-61px] left-[331px] 
              bg-[rgb(217,108,255)] mix-blend-soft-light  animate-all
              blur-2xl transform-gpu"
          />
          <div
            className="absolute w-[380px] h-[269px] top-[64px] left-[-92px]  animate-all
              rotate-[23deg] bg-[rgb(243,172,255)] mix-blend-soft-light 
              blur-2xl transform-gpu"
          />
        </div>
      </div>
    </div>
  );
};

export default ImagePlaceholder;
