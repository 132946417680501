import React from 'react';
import { useDraggableContext } from '../../context/DraggableContext';

interface AddCardProps {
  onClick: (index: number) => void;
  index: number;
  className?: string;
}

const AddCard: React.FC<AddCardProps> = ({ onClick, index, className }) => {
  const { snapshot } = useDraggableContext();
  return snapshot && !snapshot.isDragging ? (
    <div
      className={className}
      style={{
        background: 'linear-gradient(179.85deg, #c6c6c6 0%, #ededed 50%, #c6c6c6 100%)',
        padding: '1px',
      }}
    >
      <div onClick={() => onClick(index)} className={className}>
        +
      </div>
    </div>
  ) : null;
};

export default AddCard;
