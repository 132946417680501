"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logRequest = void 0;
__exportStar(require("./Scene"), exports);
__exportStar(require("./KeyVisual"), exports);
__exportStar(require("./Story"), exports);
__exportStar(require("./User"), exports);
__exportStar(require("./ImageMeta"), exports);
__exportStar(require("./JobData"), exports);
__exportStar(require("./Properties"), exports);
__exportStar(require("./Assets"), exports);
__exportStar(require("./MailGun"), exports);
__exportStar(require("./Opossum"), exports);
const logRequest = () => {
    console.log('Request logged at', new Date().toISOString());
};
exports.logRequest = logRequest;
