/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorResult, SuccessResult, UserId } from '@try-saga/storybuilder-iso';

export interface LoginPayload {
  data: Record<string, string>;
  callback?: (result: { isVerified: boolean }) => void;
}
export interface SignupPayload {
  data: Record<string, string>;
  callback?: () => void;
}

export interface LogOutPayload {
  callback?: () => void;
}

export interface UpdatePayload {
  data: FormData;
  callback?: () => void;
}

export interface SocialLoginPayload {
  access_token: string;
  callback?: () => void;
}

export interface ForgotPasswordPayload {
  data: Record<string, string>;
  callback?: () => void;
}

export interface ValidateTokenPayload {
  token: string;
  callback?: (result: { success: boolean; error: string; email: string }) => void;
}
export interface InviteUserPayload {
  script: string;
  callback?: (result: { success: SuccessResult[]; error: ErrorResult[] }) => void;
}

export type UserType = 'email' | 'google' | 'apple' | 'normal' | null;
export type UserPermission = 'sysadmin' | 'user';

export interface UserState {
  id: UserId;
  email: string;
  first_name: string;
  last_name: string;
  image: string;
  type: UserType;
  isVerified: boolean;
  permission: UserPermission;
}

const initialState: UserState = {
  id: UserId.of(''),
  email: '',
  first_name: '',
  last_name: '',
  image: '',
  type: null,
  isVerified: false,
  permission: 'user',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.image = action.payload.image;
      state.type = action.payload.type;
      state.isVerified = action.payload.isVerified;
      state.permission = action.payload.permission;
    },
    update: (state, action: PayloadAction<UpdatePayload>) => {},
    updateUserSuccess: (state, action: PayloadAction<UserState>) => {
      state.email = action.payload.email ?? state.email;
      state.first_name = action.payload.first_name ?? state.first_name;
      state.last_name = action.payload.last_name ?? state.last_name;
      state.image = action.payload.image ?? state.image;
      state.type = action.payload.type ?? state.type;
      state.isVerified = action.payload.isVerified ?? state.isVerified;
      state.permission = action.payload.permission ?? state.permission;
    },
    deleteUser: (state) => {
      state.id = UserId.of('');
      state.email = '';
      state.first_name = '';
      state.last_name = '';
      state.image = '';
      state.type = null;
      state.isVerified = false;
      state.permission = 'user';
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },

    socialLogin: (state, action: PayloadAction<SocialLoginPayload>) => {},

    login: (state, action: PayloadAction<LoginPayload>) => {},

    logout: (state, action: PayloadAction<LogOutPayload>) => {},

    signup: (state, action: PayloadAction<SignupPayload>) => {},

    forgotPassword: (state, action: PayloadAction<ForgotPasswordPayload>) => {},

    verifyToken: (state, action: PayloadAction<ValidateTokenPayload>) => {},

    inviteUser: (state, action: PayloadAction<InviteUserPayload>) => {},
  },
});

export const {
  set,
  update,
  deleteUser,
  socialLogin,
  login,
  updateUserSuccess,
  logout,
  signup,
  setImage,
  forgotPassword,
  verifyToken,
  inviteUser,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
