import React, { FC, ReactNode, useEffect } from 'react';
import Header from './Header';
import { useBlockRouteChange } from '../utility/routeGuard';
import ConfirmModal from '../components/confirm/confirmModel';
import { RootState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { cancelConfirmPopup, setIsConfirmed } from '../redux/reducers/app';
import { Navigate, useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const confirmBox = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirm = () => {
    dispatch(setIsConfirmed());
    if (confirmBox.toLocation) {
      navigate(confirmBox.toLocation);
      dispatch(cancelConfirmPopup());
    }
  };

  const handleCancel = () => {
    dispatch(cancelConfirmPopup());
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex-1">{children}</div>
      {confirmBox.showModal && (
        <ConfirmModal
          message="Warning: The character you designed will be lost when you leave this page"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          position="top-center"
        />
      )}
    </div>
  );
};

export default Layout;
