import React, { useEffect, useRef } from 'react';
import { useFormContext } from '../../../context/FormContext';
import { Input } from '../../lib';
import { useTrackEvent } from '../../../config/amplitude';

interface FileInputProps {
  accept?: string;
  name: string;
  children: React.ReactNode;
  className?: string;
  isEdit?: boolean;
  onError?: (message: string) => void;
  multiple?: boolean;
  reset?: boolean;
}

export const FileInput: React.FC<FileInputProps> = ({
  accept,
  children,
  className,
  isEdit = true,
  onError,
  name,
  multiple,
  reset,
}) => {
  const { trackEvent } = useTrackEvent();
  const inputRef = useRef<HTMLInputElement>(null);
  const { updateField } = useFormContext();

  const handleClick = () => {
    if (inputRef.current && isEdit) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    if (reset) {
      if (inputRef.current) {
        const dataTransfer = new DataTransfer();
        inputRef.current.files = dataTransfer.files;
      }
    }
  }, [reset]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    trackEvent('upload-script', { 'upload-button': 'upload script' });
    const selectedFiles = e.target.files;
    if (!selectedFiles) {
      updateField(name, '');
      return;
    }
    if (selectedFiles) {
      const file = multiple ? selectedFiles : selectedFiles[0];
      try {
        updateField(name, file);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        onError?.(errorMessage);
      }
    }

    try {
      const file = multiple ? selectedFiles : selectedFiles[0];
      updateField(name, file);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      onError?.(errorMessage);
    }
  };

  return (
    <div className={className} onClick={handleClick}>
      {children}
      <Input
        multiple={multiple}
        inputRef={inputRef}
        type="file"
        className="hidden"
        accept={accept}
        onChange={handleFileChange}
        name={name}
      />
    </div>
  );
};
