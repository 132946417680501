import React, { useEffect, useState } from 'react';
import { Button, FileInput, Form, Image, Label, TextInput } from '../components/lib';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useFormContext } from '../context/FormContext';
import { useDispatch } from 'react-redux';
import { uploadStory } from '../redux/reducers/story';
import { getCdnUrl } from '../utility/cdnUtility';
import { useNavigate } from 'react-router-dom';
import ScenesSkeleton from '../components/scenes/skeleton';
import { getEnvVariable } from '../utility/helpers';
import FilePreview from '../components/form/filePreview';
import { clearState } from '../redux/reducers/app';
import { useTrackEvent } from '../config/amplitude';

const UploadScript = () => {
  const { trackEvent } = useTrackEvent();
  const appState = useSelector((state: RootState) => state.app);
  const { form, updateField, setForm } = useFormContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  useEffect(() => {
    if (!form) return;
    const { name, scriptText, script } = form || {};
    const isScriptRequired = script?.value === '';

    const updatedForm = {
      name: { value: name?.value || '', valid: name?.valid, required: true },
      scriptText: {
        value: scriptText?.value || '',
        valid: isScriptRequired ? scriptText?.valid : true,
        required: isScriptRequired,
      },
      script: { value: script?.value || '', valid: script?.valid, required: !isScriptRequired },
    };

    if (JSON.stringify(form) !== JSON.stringify(updatedForm)) {
      setForm(updatedForm);
    }
  }, [form, setForm]);

  const handleSubmit = (data: FormData) => {
    trackEvent('decompose-script', { 'smart-upload': 'decompose script' });

    const scriptText = form?.scriptText?.value;
    if (scriptText) {
      const blob = new Blob([scriptText as string], { type: 'text/plain' });
      const file = new File([blob], 'script.txt', { type: 'text/plain' });
      data.append('script', file);
    }

    dispatch(
      uploadStory({
        data: data,
        callback: (id: string) => {
          navigate(`/story/${id}`);
        },
      }),
    );
  };

  const handleFileDelete = (name: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    updateField(name, '');
  };

  return appState.loading || appState.error ? (
    <ScenesSkeleton
      subHeading={
        appState.error
          ? 'Failed to decompose script, please try again'
          : `Please wait! It can take up to ${parseInt(getEnvVariable('REACT_APP_POLLING_THRESHOLD') || '0') / 60000} min.`
      }
      redirect
      success={!appState.error}
      text={appState.error ? 'Error' : 'Analyzing Story'}
    />
  ) : (
    <>
      <div className="h-full pl-[20px] flex flex-col">
        <div className="mt-[37px] ml-[51px]">
          <h3 className="mb-[30px] text-[20px] leading-[30.16px]">Overview</h3>
        </div>
        <Form onSubmit={handleSubmit} className="flex-1" hasFormData>
          <div className="flex rounded-ss-[20px] h-full w-full">
            <div className="ml-[51px] w-full max-w-[335px]">
              <div
                style={{
                  background: 'linear-gradient(92.76deg, #c6c6c6 1.17%, #ededed 50%, #c6c6c6 100%)',
                }}
                className="rounded-xl p-[1px] max-w-[300px]"
              >
                <div className="w-full flex flex-col gap-y-[15px] bg-[#F0F0F0] rounded-xl p-[20px] max-w-[300px]">
                  <div>
                    <Label className="text-[14px] leading-[17.6px] mb-[10px]" htmlFor="episode" text="Episode Title" />
                    <div
                      style={{
                        background: 'linear-gradient(92.76deg, #c6c6c6 1.17%, #ededed 50%, #c6c6c6 100%)',
                      }}
                      className="p-[1px] rounded-xl"
                    >
                      <TextInput
                        type="text"
                        placeholder="Enter"
                        name="name"
                        value={form?.name?.value as string}
                        required
                        className="bg-white border-[#0000001A] max-h-[36px] text-[12px]"
                        onChange={(e) => updateField('name', e.target.value)}
                        valid={form?.name?.valid === undefined ? undefined : !!form?.name?.valid}
                        minLimit={3}
                        characterLimit={40}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full pr-[200px] flex flex-col gap-y-[13px] overflow-hidden flex-1">
              {!form.script?.value ? (
                <TextInput
                  type="textarea"
                  name="scriptText"
                  characterLimit={20000}
                  minLimit={50}
                  onChange={(e) => updateField('scriptText', e.target.value)}
                  required
                  value={form?.scriptText?.value as string}
                  valid={form?.scriptText?.valid ?? undefined}
                  className="h-full rounded-2xl text-[12px] border-[1px] border-solid flex-1"
                  placeholder="Copy and paste your script, story, or free write the idea behind your saga ..."
                />
              ) : (
                <FilePreview handleFileDelete={handleFileDelete} />
              )}
              <div className="flex gap-x-[17px] items-center">
                <span className="font-poppins font-[500] text-[14px] leading-[20px] tracking-[1px]">Or</span>
                <div className="flex justify-between">
                  <FileInput
                    name="script"
                    reset={form?.script?.value === ''}
                    className="flex gap-x-[8px] cursor-pointer"
                    accept="text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onError={setErrorMessage}
                  >
                    <div className="flex gap-x-[8px] rounded-[10px] px-[40px] py-[8px] bg-[#5C9DFF80] max-w-[156px] text-center font-poppins tracking-[1px] text-[14px] font-[500]">
                      <Image src={getCdnUrl('icons/upload.png')} alt="upload" />
                      <span>Upload</span>
                    </div>
                  </FileInput>
                </div>
              </div>
              <div className="mt-[42px] mb-[56px] flex items-center justify-end gap-x-[7px]">
                {errorMessage && (
                  <div className="flex max-w-[215px] items-center justify-center gap-x-[11px] py-[7px]">
                    <div className="w-[20px] h-[17px]">
                      <Image src={getCdnUrl('icons/error.svg')} alt="error" className="object-cover h-full w-full" />
                    </div>
                    <span className="text-black text-[12px] leading-[12.5px]">{errorMessage}</span>
                  </div>
                )}
                <Button
                  text="Smart Upload"
                  type="submit"
                  flex
                  relative
                  className="float-right max-w-[167px] rounded-[10px] py-[7px] px-[52px] text-[12px]"
                  color="blue"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default UploadScript;
