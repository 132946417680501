import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { DroppableItemType } from './droppable';

interface DragNDropProps {
  children: React.ReactNode;
  items?: DroppableItemType[];
  update: (items: DroppableItemType[] | undefined) => void;
}

const reorder = (list: DroppableItemType[] | undefined, startIndex: number, endIndex: number) => {
  let result;
  if (list) {
    result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  }

  return result;
};

export const DragNDrop: React.FC<DragNDropProps> = (props) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(props.items, result.source.index, result.destination.index);

    props.update(items);
  };
  return <DragDropContext onDragEnd={onDragEnd}>{props.children}</DragDropContext>;
};
