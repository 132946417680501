import { CreateAsset, TrainAsset } from '../../redux/reducers/assets';
import axios from './axiosConfig';

export const fetchCharacterSheet = async (id: string) => axios.get(`/assets/get/${id}`);

export const createAssetApi = async (payload: CreateAsset) => axios.post('/assets/create', payload);

export const trainAssetApi = async (payload: TrainAsset) => axios.post('/assets/trainAsset', payload);

export const getAssetsApi = async () => axios.get('/assets/get');
