import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

export type DroppableItemType = { [key: string]: string | boolean | object | number | undefined };

interface DroppableItemProps {
  children: React.ReactNode;
}

export const DroppableItems: React.FC<DroppableItemProps> = (props) => {
  return (
    <Droppable droppableId="droppable" direction="horizontal">
      {(provided) => (
        <div className="flex gap-x-[5px] overflow-x-scroll" ref={provided.innerRef} {...provided.droppableProps}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
