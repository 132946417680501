import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormContext } from '../../context/FormContext';
import { Form, EmailInput, Button, Image } from '../../components/lib';
import { isLoggedIn } from '../../utility/tokenUtility';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/reducers/user';
import { getCdnUrl } from '../../utility/cdnUtility';
import { useTrackEvent } from '../../config/amplitude';

const initialValues = {
  email: { value: '', valid: undefined, required: true },
};

const ForgotPassword: React.FC = () => {
  const { trackEvent } = useTrackEvent();
  const { form, updateField, setForm } = useFormContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/home');
    } else {
      setForm({ ...initialValues });
    }
  }, [navigate, setForm]);

  const handleSubmit = (data: Record<string, string>) => {
    trackEvent('forgot-password', { userEmail: data.email }, data.email);
    dispatch(
      forgotPassword({
        data,
        callback: () => {
          setForm({ ...initialValues });
          navigate('/');
        },
      }),
    );
  };

  return (
    <div
      id="hero"
      className="lg:!bg-none w-full bg-cover bg-center bg-no-repeat h-full bg-fixed flex justify-center lg:justify-between"
      style={{ backgroundImage: `url('${getCdnUrl('images/mobile-login.png')}')` }}
    >
      <div
        className="hidden lg:block bg-cover h-full w-full max-w-[756px] bg-no-repeat"
        style={{
          backgroundImage: `url('${getCdnUrl('images/hero.png')}')`,
        }}
      ></div>
      <div className="mx-auto my-auto">
        <div className="rounded-lg p-4 text-center bg-white lg:bg-transparent overflow-auto">
          <div className="min-w-[360px]">
            <div className="flex flex-col">
              <h2 className="text-[36px]">Forgot Password?</h2>
            </div>
            <Form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-[32px]">
              <EmailInput
                name="email"
                required
                placeholder="Email"
                value={form.email?.value as string}
                onChange={(e) => updateField('email', e.target.value)}
                valid={form.email?.valid}
              />

              <Button text="Submit" type="submit" className="rounded-[30px] text-[14px] py-[14px]" tracking />
            </Form>
          </div>
          <div className="text-[#11111180] text-[12px] mt-[25px]">
            <Link to="/" className="flex gap-[20px] items-center justify-center">
              <Image src={getCdnUrl('icons/arrow-back-icon.png')} alt="Back" />
              <label className="cursor-pointer">Back to login</label>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
