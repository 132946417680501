import { takeLatest, put } from 'redux-saga/effects';
import {
  deleteUser,
  set,
  updateUserSuccess,
  LoginPayload,
  SocialLoginPayload,
  UpdatePayload,
  LogOutPayload,
  ForgotPasswordPayload,
  ValidateTokenPayload,
  InviteUserPayload,
  SignupPayload,
} from '../reducers/user';
import { PayloadAction } from '@reduxjs/toolkit';
import { setError, setSuccess, startLoading, stopLoading } from '../reducers/app';
import { toast } from 'react-toastify';
import { handleRequest } from './utils';
import {
  forgotPasswordApi,
  inviteUserApi,
  loginApi,
  signUpApi,
  socialLoginApi,
  updateUserApi,
  verifyTokenApi,
} from '../../config/api/user';
import logger from '../../logger';
import { AxiosError, AxiosResponse } from 'axios';

function* socialLogin(action: PayloadAction<SocialLoginPayload>) {
  const response = yield* handleRequest(socialLoginApi, undefined, undefined, action.payload);
  if (response) {
    const {
      data: { token, message, data },
    } = response;
    localStorage.setItem('token', token);
    yield put(set(data));
    yield put(setSuccess(message));
    toast.success(message);
    if (action.payload.callback) action.payload.callback();
  }
}

function* login(action: PayloadAction<LoginPayload>) {
  const response = yield* handleRequest(loginApi, undefined, undefined, action.payload);
  if (response) {
    const {
      message,
      data,
      data: { token },
    } = response;
    localStorage.setItem('token', token);
    yield put(set(data));
    yield put(setSuccess(message));
    toast.success(message);

    if (action.payload.callback) action.payload.callback({ isVerified: data.isVerified });
  }
}

function* signup(action: PayloadAction<SignupPayload>) {
  const response = yield* handleRequest(signUpApi, undefined, undefined, action.payload);
  if (response) {
    const { message } = response;
    yield put(setSuccess(message));
    toast.success(message);
    if (action.payload.callback) action.payload.callback();
  }
}

function* logout(action: PayloadAction<LogOutPayload>) {
  localStorage.removeItem('token');
  yield put(deleteUser());
  yield put(setSuccess('Logged out successfully'));
  toast.success('Logged out successfully');
  if (action.payload.callback) action.payload.callback();
}

function* updateUser(action: PayloadAction<UpdatePayload>) {
  const response = yield* handleRequest(updateUserApi, undefined, undefined, action.payload);
  if (response) {
    const { message, data } = response;
    localStorage.removeItem('resetToken');
    yield put(updateUserSuccess(data));
    yield put(setSuccess(message));
    toast.success(message);
    if (action.payload.callback) action.payload.callback();
  }
}

function* forgotPassword(action: PayloadAction<ForgotPasswordPayload>) {
  const response = yield* handleRequest(forgotPasswordApi, undefined, undefined, action.payload);
  if (response) {
    const { message } = response;
    yield put(setSuccess(message));
    toast.success(message);
    if (action.payload.callback) action.payload.callback();
  }
}

function* verifyToken(action: PayloadAction<ValidateTokenPayload>) {
  try {
    localStorage.setItem('resetToken', action.payload.token);
    const response = yield* handleRequest(verifyTokenApi, undefined, undefined, action.payload);
    if (response) {
      const { message, data } = response;
      if (data.id) {
        yield put(setSuccess(message));
      }
      if (action.payload.callback) action.payload.callback({ success: true, error: message, email: data.email });
    }
  } catch (e) {
    localStorage.removeItem('resetToken');
    logger.error('Error:', e);
    if (e instanceof AxiosError) {
      const errorMsg = e.response?.data;
      if (action.payload.callback) action.payload.callback({ success: false, error: errorMsg, email: '' });
    }
  } finally {
    yield put(stopLoading());
  }
}

function* inviteUser(action: PayloadAction<InviteUserPayload>) {
  const response = yield* handleRequest(inviteUserApi, undefined, undefined, action.payload);
  if (response) {
    const { data } = response;

    if (action.payload.callback) action.payload.callback({ success: data.success, error: data.error });
  }
}

export function* userSagas() {
  yield takeLatest('user/socialLogin', socialLogin);
  yield takeLatest('user/login', login);
  yield takeLatest('user/signup', signup);
  yield takeLatest('user/logout', logout);
  yield takeLatest('user/update', updateUser);
  yield takeLatest('user/forgotPassword', forgotPassword);
  yield takeLatest('user/verifyToken', verifyToken);
  yield takeLatest('user/inviteUser', inviteUser);
}
