import React from 'react';
import { Image } from '../lib';
import { CardProps } from './profileInterfaces';
import { dateInDays } from '../../utility/helpers';
import { getCdnUrl } from '../../utility/cdnUtility';
import { useNavigate } from 'react-router-dom';

const ProfileCard: React.FC<CardProps> = (props) => {
  const navigate = useNavigate();
  let color = '#FFDE00';
  let textColor = '#303030';

  if (props.status === 'completed') {
    color = '#0EAA00';
    textColor = '#FFFDF8';
  } else if (props.status === 'errored') {
    color = '#FF0000';
    textColor = '#FFFDF8';
  }

  return (
    <div className="w-[157px] max-h-[245px] mb-[20px]">
      <div
        onClick={() => props.goto && navigate(props.goto)}
        className={`relative w-full h-[118px] cursor-pointer  bg-[#F9F8FA] flex justify-center items-center ${props.image != null ? '' : 'border-[1px] rounded-lg border-solid border-[#000000]'}`}
      >
        {props.image != null ? (
          <Image alt="card-image" src={props.image} className="max-w-full max-h-full rounded-lg" />
        ) : (
          <Image
            alt="card-image"
            src={getCdnUrl('images/movie.png')}
            className="max-w-[58px] max-h-[46px] rounded-lg"
          />
        )}
        {props.length && (
          <span className="font-inter px-[6.1px] py-[1.61px] font-semibold absolute right-[6px] rounded-[2px] bg-black text-[#FFFDF8] text-[6.5px] bottom-[4px]">
            {props.length}
          </span>
        )}
      </div>
      <div className="mt-[5px] pl-[2px]">
        <h2
          onClick={() => props.goto && navigate(props.goto)}
          className="text-[14px] font-semibold mb-[5px] cursor-pointer font-inter"
        >
          {props.title}
        </h2>
        {props.status && (
          <div className="flex gap-x-[9px] w-full">
            <div
              className="rounded-[5px] w-full max-w-[76px] max-h-[16px] flex justify-center items-center gap-x-[6px] text-[8px] font-[500]"
              style={{ backgroundColor: color, color: textColor }}
            >
              <span className="text-center py-[2px] pl-[3px] font-poppins">
                {props.status.charAt(0).toUpperCase() + props.status.slice(1)}
              </span>
            </div>
          </div>
        )}
        <div className="flex gap-x-[5px] text-[10px] font-semibold font-inter text-[#A3A3A6]">
          {props.date != null && <span>{dateInDays(props.date)}</span>}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
