import React from 'react';
import Style from './input.tailwind';
import { classHelper } from '../../class/index';
import { Input, Label } from '../../lib';
import { TextInputProps } from '../formInterfaces';
import { useFormContext } from '../../../context/FormContext';
import './input.module.scss';

export const TextInput: React.FC<TextInputProps> = (props) => {
  const { form, setForm } = useFormContext();
  const field = form[props.name] || { value: props.value || '', valid: undefined };

  const textStyle = classHelper(Style, {
    textbox: true,
    className: props.className,
    error: field.valid === false,
  });

  function validate(value: string): boolean {
    const isRequiredValid = (value: string, required?: boolean) => {
      return !required || value !== '';
    };

    const isMinLimitValid = (value: string, minLimit?: number) => {
      return !(minLimit && value.trim().length < minLimit);
    };

    const isValid = isRequiredValid(value, props.required) && isMinLimitValid(value, props.minLimit);

    /* Update form with the validation result */
    setForm((prevForm) => ({
      ...prevForm,
      [props.name]: { ...prevForm[props.name], value, valid: isValid },
    }));

    return isValid;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    validate(value);
    props.onChange?.(e);
  };

  return (
    <div className={Style.input}>
      {props.label && <Label text={props.label} htmlFor={props.name} />}
      <Input
        type={props.type}
        id={props.id}
        name={props.name}
        value={(field.value as string) || ''}
        className={textStyle}
        characterLimit={props.characterLimit}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onFocus={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => props.onFocus?.(e)}
        onChange={handleChange}
      />
    </div>
  );
};
