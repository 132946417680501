import React from 'react';
import { DroppableItemType } from '../dragndrop/droppable';
import { KeyVisual, KeyVisualId } from '@try-saga/storybuilder-iso';
import { DragNDropContainer } from '../lib';
import DraggableItem from '../dragndrop/draggableItem';
import ScriptKeyVisual from './keyVisual';
import AddCard from './addCard';
import { useDispatch } from 'react-redux';
import { handleUpdateKeyVisuals } from '../../redux/reducers/scenes';
import { useTrackEvent } from '../../config/amplitude';

interface KeyVisualsProps {
  keyVisuals: KeyVisual[] | undefined;
  sceneIndex: number;
  sceneId?: string;
  handleDeleteVisual: (index: number, sceneIndex: number, id?: KeyVisualId) => void;
  handleAddVisual: (index: number, sceneIndex: number) => void;
}

const KeyVisuals: React.FC<KeyVisualsProps> = ({
  keyVisuals,
  sceneIndex,
  handleAddVisual,
  handleDeleteVisual,
  sceneId,
}) => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useDispatch();
  const update = (items?: DroppableItemType[]) => {
    trackEvent('reOrder-keyVisual', { 're-order': 'keyVisual' });
    dispatch(handleUpdateKeyVisuals({ sceneIndex, visuals: items }));
  };

  return keyVisuals?.length ? (
    <DragNDropContainer items={keyVisuals as unknown as DroppableItemType[]} update={update}>
      {keyVisuals?.map((keyVisual, index) => {
        return (
          <div className="flex gap-x-[5px]" key={index}>
            <DraggableItem id={keyVisual.order} index={index} key={keyVisual.id}>
              <ScriptKeyVisual
                sceneIndex={sceneIndex}
                sceneId={sceneId}
                index={index}
                id={KeyVisualId.of(keyVisual.id || '')}
                parentId={keyVisual.parentId}
                createdBy={keyVisual.createdBy}
                properties={keyVisual.properties}
                images={keyVisual.images}
                order={keyVisual.order}
                handleDeleteScene={handleDeleteVisual}
              />
              <AddCard
                className="text-[20px] bg-[#EDEEEF] rounded-[5px] font-semibold font-inter cursor-pointer max-h-[41px] max-w-[15px] h-full w-full flex justify-center items-center my-auto"
                onClick={() => handleAddVisual(index, sceneIndex)}
                index={index}
              />
            </DraggableItem>
          </div>
        );
      })}
    </DragNDropContainer>
  ) : (
    <div className="flex justify-center items-center h-[500px]">
      <AddCard
        className="text-[20px] bg-[#EDEEEF] rounded-[5px] font-semibold font-inter cursor-pointer max-h-[60px] max-w-[60px] h-full w-full flex justify-center items-center my-auto"
        onClick={() => handleAddVisual(0, sceneIndex)}
        index={0}
      />
    </div>
  );
};

export default KeyVisuals;
