import { AxiosError, AxiosResponse } from 'axios';
import logger from '../../logger';
import { setError, startLoading } from '../reducers/app';
import { put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { pollingSaga } from './pollingSaga';
import {
  DeleteKeyVisual,
  GenerateKeyVisual,
  GetKeyVisual,
  GetScenes,
  setKeyVisuals,
  setScenesData,
  SetVisuals,
  startKeyVisualPoll,
  updateApiKeyVisuals,
  updateKeyVisual,
} from '../reducers/scenes';
import { RootState } from '../store';
import { getEnvVariable } from '../../utility/helpers';
import { handleRequest } from './utils';
import {
  deleteKeyVisualById,
  fetchKeyVisualById,
  fetchScenesForStory,
  generateKeyVisualImage,
  updateKeyVisualsApi,
} from '../../config/api/scenes';
import { KeyVisualId, StoryId } from '@try-saga/storybuilder-iso';

function* getScene(action: PayloadAction<GetScenes>) {
  const response = yield* handleRequest(
    fetchScenesForStory,
    (data) => setScenesData(data),
    undefined,
    StoryId.of(action.payload.id),
  );

  if (response && action.payload.callback) {
    action.payload.callback(action.payload.id);
  }
}

function* deleteVisual(action: PayloadAction<DeleteKeyVisual>) {
  const { id } = action.payload;

  if (id) {
    const response = yield* handleRequest(deleteKeyVisualById, undefined, undefined, KeyVisualId.of(id));
    if (response && action.payload.callback) action.payload.callback();
  }
}

function* handleUpdateKeyVisuals(action: PayloadAction<SetVisuals>) {
  yield put(setKeyVisuals(action.payload));

  const updatedKeyvisuals = (state: RootState) => state.scenes.scenes[action.payload.sceneIndex]?.keyVisuals;
  const updatedKeyVisuals: ReturnType<typeof updatedKeyvisuals> = yield select(
    (state) => state.scenes.scenes[action.payload.sceneIndex]?.keyVisuals,
  );

  yield put(updateApiKeyVisuals({ sceneIndex: action.payload.sceneIndex, visuals: updatedKeyVisuals }));
}

function* updateKeyVisuals(action: PayloadAction<SetVisuals>) {
  yield* handleRequest(
    updateKeyVisualsApi,
    (data) => setKeyVisuals({ sceneIndex: action.payload.sceneIndex, visuals: data.data }),
    undefined,
    action.payload,
  );
}

function* pollFetchScene(action: PayloadAction<GetScenes>) {
  try {
    yield put(startLoading());
    const stopCondition = (response: AxiosResponse) => {
      return response.data.status === 'completed';
    };

    const REACT_APP_POLLING_INTERVAL = getEnvVariable('REACT_APP_POLLING_INTERVAL');
    const REACT_APP_POLLING_THRESHOLD = getEnvVariable('REACT_APP_POLLING_THRESHOLD');

    yield* pollingSaga<AxiosResponse>(
      () => fetchScenesForStory(StoryId.of(action.payload.id)),
      function* (response: AxiosResponse) {
        yield put(setScenesData(response));
        if (action.payload.callback) action.payload.callback(action.payload.id);
      },
      stopCondition,
      REACT_APP_POLLING_INTERVAL ? parseInt(REACT_APP_POLLING_INTERVAL) : undefined,
      REACT_APP_POLLING_THRESHOLD ? parseInt(REACT_APP_POLLING_THRESHOLD) : undefined,
      function* () {
        yield put(setError('Our bad, please try again'));
      },
    );
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  }
}

function* generateKeyVisual(action: PayloadAction<GenerateKeyVisual>) {
  yield* handleRequest(generateKeyVisualImage, undefined, action.payload.callback, action.payload);
  const {
    data: { keyVisualId, sceneIndex },
    callback,
  } = action.payload;
  if (keyVisualId) {
    yield put(startKeyVisualPoll({ id: keyVisualId, sceneIndex: sceneIndex, callback: callback }));
  }
}

function* pollFetchKeyVisual(action: PayloadAction<GetKeyVisual>) {
  try {
    yield put(startLoading());
    const stopCondition = (response: AxiosResponse) => {
      return Object.keys(response.data?.data?.images || {}).length > 0;
    };

    const REACT_APP_POLLING_INTERVAL = getEnvVariable('REACT_APP_POLLING_INTERVAL');
    const REACT_APP_POLLING_THRESHOLD = getEnvVariable('REACT_APP_POLLING_THRESHOLD');

    yield* pollingSaga<AxiosResponse>(
      () => fetchKeyVisualById(action.payload.id),
      function* (response: AxiosResponse) {
        action.payload.callback();
        yield put(updateKeyVisual({ keyVisual: response.data.data, sceneIndex: action.payload.sceneIndex }));
      },
      stopCondition,
      REACT_APP_POLLING_INTERVAL ? parseInt(REACT_APP_POLLING_INTERVAL) : undefined,
      REACT_APP_POLLING_THRESHOLD ? parseInt(REACT_APP_POLLING_THRESHOLD) : undefined,
      function* () {
        yield put(setError('Our bad, please try again'));
      },
    );
  } catch (e) {
    logger.error('Error:', e);
    if (e instanceof AxiosError)
      yield put(setError(e?.response?.data?.error || e?.response?.data || e?.response?.data?.message));
  }
}

export function* scenesSaga() {
  yield takeLatest('scenes/getScene', getScene);
  yield takeLatest('scenes/startScenePoll', pollFetchScene);
  yield takeLatest('scenes/startKeyVisualPoll', pollFetchKeyVisual);
  yield takeLatest('scenes/deleteKeyVisual', function* (action: PayloadAction<DeleteKeyVisual>) {
    if (!action.payload.id?.includes('_new')) {
      yield deleteVisual(action);
    }
  });
  yield takeLatest('scenes/updateApiKeyVisuals', updateKeyVisuals);
  yield takeLatest('scenes/generateKeyVisual', generateKeyVisual);
  yield takeLatest('scenes/handleUpdateKeyVisuals', handleUpdateKeyVisuals);
}
