import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from '../../context/FormContext';
import { Form, Button, PasswordInput, Image } from '../../components/lib';
import { isLoggedIn } from '../../utility/tokenUtility';
import { useDispatch } from 'react-redux';
import { update, verifyToken } from '../../redux/reducers/user';
import { getCdnUrl } from '../../utility/cdnUtility';
import { useTrackEvent } from '../../config/amplitude';
const initialValues = {
  password: { value: '', valid: undefined, required: true },
  confirm_password: { value: '', valid: undefined, required: true },
  purpose: { value: 'reset-password', valid: undefined, required: true },
};

const ResetPassword: React.FC = () => {
  const { token } = useParams();
  const { trackEvent } = useTrackEvent();

  const { form, updateField, setForm } = useFormContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/home');
    } else {
      setForm({ ...initialValues });
    }
  }, [navigate, setForm]);

  useEffect(() => {
    if (token) {
      dispatch(
        verifyToken({
          token,
          callback: (response) => {
            setTokenExpired(!response.success);
            trackEvent('reset-password', { userEmail: response.email }, response.email);
            if (response.success) {
              navigate('/reset-password');
            }
          },
        }),
      );
    }
  }, [token, dispatch, navigate, trackEvent]);

  const handleSubmit = (data: FormData) => {
    dispatch(
      update({
        data: data,
        callback: () => {
          setForm({ ...initialValues });
          navigate('/');
        },
      }),
    );
  };

  return (
    <div
      id="hero"
      className="lg:!bg-none w-full bg-cover bg-center bg-no-repeat h-full bg-fixed flex justify-center lg:justify-between"
      style={{ backgroundImage: `url('${getCdnUrl('images/mobile-login.png')}')` }}
    >
      <div
        className="hidden lg:block bg-cover h-full w-full max-w-[756px] bg-no-repeat"
        style={{
          backgroundImage: `url('${getCdnUrl('images/hero.png')}')`,
        }}
      ></div>
      <div className="mx-auto my-auto">
        <div className="rounded-lg p-4 text-center bg-white lg:bg-transparent overflow-auto">
          <div className="min-w-[360px]">
            <div className="flex flex-col">
              <h2 className="text-[36px]">{tokenExpired ? 'Password reset link expired' : 'Reset Password'}</h2>
            </div>
            {!tokenExpired && (
              <Form onSubmit={handleSubmit} hasFormData className="flex flex-col gap-4 mt-[32px]">
                <PasswordInput
                  name="password"
                  required
                  placeholder="Password"
                  value={form.password?.value as string}
                  onChange={(e) => updateField('password', e.target.value)}
                  valid={form.password?.valid}
                  complexPassword
                />
                <PasswordInput
                  name="confirm_password"
                  required
                  placeholder="Re-enter password"
                  value={form.confirm_password?.value as string}
                  onChange={(e) => updateField('confirm_password', e.target.value)}
                  valid={form.confirm_password?.valid}
                  showHint={true}
                />

                <Button text="Submit" type="submit" className="rounded-[30px] text-[14px] py-[14px]" tracking />
              </Form>
            )}
          </div>
          {tokenExpired && (
            <div className="text-[#11111180] text-[12px] mt-[25px]">
              <Link to="/forgot-password" className="flex gap-[20px] items-center justify-center">
                <Image src={getCdnUrl('icons/arrow-back-icon.png')} alt="Back" />
                <label className="cursor-pointer">Request new link</label>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
