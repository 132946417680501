import * as LDClient from 'launchdarkly-js-client-sdk';
import { getEnvVariable } from '../utility/helpers';
import logger from '../logger';

export async function getFeatureFlags(keys: string[]): Promise<Record<string, boolean>> {
  const context = { kind: 'user', key: 'user-123' };
  return new Promise((resolve, reject) => {
    const client = LDClient.initialize(getEnvVariable('REACT_APP_DARKLY_CLIENT_KEY') || '', context);

    client.on('initialized', () => {
      const flags: Record<string, boolean> = {};
      keys.forEach((flagKey) => {
        flags[flagKey] = client.variation(flagKey, false);
      });

      resolve(flags);
    });

    client.on('failed', (error) => {
      logger.error('LaunchDarkly failed to initialize:', error);
      reject(error);
    });
  });
}
