import pino from 'pino';
import { getEnvVariable } from './utility/helpers';

const logger = pino({
  transport: {
    target: 'pino-pretty',
    options: {
      colorize: true,
    },
  },
  level: getEnvVariable('NODE_ENV') === 'development' ? 'debug' : 'info',
});

export default logger;
