const Style = {
  input: 'relative last:mb-0 flex-1',
  textbox: `relative block w-full p-3 rounded border border-solid
      focus:bg-slate-50 appearance-none disabled:opacity-50 bg-[#F0F0F0] outline-none rounded-xl tracking-[2px] focus:border-[#6100ff78]`,
  textArea: `focus:bg-transparent`,
  cardbox: '!p-4',
  successIcon: 'absolute top-[1em] right-3 z-[100]',
  eyeIcon: 'absolute top-[1em] right-3 z-[100] cursor-pointer',
  error: 'relative text-red-500 mb-0 !border !border-solid !border-red-500 !bg-red-200 placeholder:text-red-600',
  warning: 'bg-orange-50 border border-solid border-orange-500',
};

export default Style;
