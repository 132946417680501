import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openConfirmPopup, setToLocation } from '../redux/reducers/app';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export const useBlockRouteChange = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currLocation = useLocation();
  const apiInProgress = useSelector((state: RootState) => state.app.loading);

  const navigateWithConfirmation = (location?: string): boolean => {
    let switchTab = false;

    if (location) {
      dispatch(setToLocation(location));
    }

    if (apiInProgress && currLocation.pathname === '/kitchen') {
      dispatch(openConfirmPopup());
      switchTab = false;
    } else if (location) {
      navigate(location);
      switchTab = true;
    } else {
      switchTab = true;
    }

    return switchTab;
  };

  return { navigateWithConfirmation };
};
