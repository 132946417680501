import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, EmailInput, Form, PasswordInput, TextInput } from '../../components/lib';
import { signup } from '../../redux/reducers/user';
import SocialLogin from '../../components/socialLogin';
import { isLoggedIn } from '../../utility/tokenUtility';
import { useFormContext } from '../../context/FormContext';
import { useDispatch } from 'react-redux';
import { getCdnUrl } from '../../utility/cdnUtility';

const Signup = () => {
  const navigate = useNavigate();
  const { form, setForm, updateField } = useFormContext();
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      email: { value: '', valid: undefined, required: true },
      password: { value: '', valid: undefined, required: true },
      first_name: { value: '', valid: undefined, required: true },
      last_name: { value: '', valid: undefined, required: true },
      confirm_password: { value: '', valid: undefined, required: true },
    }),
    [],
  );

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/home');
    } else {
      setForm({ ...initialValues });
    }
  }, [initialValues, setForm, navigate]);

  const handleSubmit = (data: Record<string, string>) => {
    dispatch(
      signup({
        data,
        callback: () => {
          navigate('/');
        },
      }),
    );
  };

  return (
    <div
      id="hero"
      className="lg:!bg-none lg:justify-between w-full bg-cover bg-center bg-no-repeat h-full bg-fixed flex justify-center lg:h-screen"
      style={{ backgroundImage: `url('${getCdnUrl('images/mobile-login.png')}')` }}
    >
      {/* Left Section */}
      <div
        className="hidden lg:block bg-cover h-full w-full max-w-[756px] bg-no-repeat lg:h-screen"
        style={{
          backgroundImage: `url('${getCdnUrl('images/hero.png')}')`,
        }}
      ></div>
      <div className="mx-auto mt-[30px] lg:mt-0 overflow-y-auto max-h-[724px] 2xl:max-h-full">
        <div className="rounded-lg p-4 text-center bg-white lg:bg-transparent overflow-y-auto">
          <div className="min-w-[360px]">
            <h2 className="text-[36px]">Welcome to Saga</h2>
            <p className="text-[12px] leading-[14px]">
              Already have an account?{' '}
              <Link to="/" className="text-[#6100FF]">
                Sign In
              </Link>
            </p>
            <Form reset={false} onSubmit={handleSubmit} className="flex flex-col gap-4 mt-[32px]">
              <TextInput
                name="first_name"
                type="text"
                required
                placeholder="First Name"
                value={form.first_name?.value as string}
                onChange={(e) => updateField('first_name', e.target.value)}
                valid={form.first_name?.valid}
                characterLimit={40}
                minLimit={3}
              />
              <TextInput
                name="last_name"
                type="text"
                required
                placeholder="Last Name"
                value={form.last_name?.value as string}
                onChange={(e) => updateField('last_name', e.target.value)}
                valid={form.last_name?.valid}
                characterLimit={40}
                minLimit={3}
              />
              <EmailInput
                name="email"
                required
                placeholder="Email"
                value={form.email?.value as string}
                onChange={(e) => updateField('email', e.target.value)}
                valid={form.email?.valid}
                characterLimit={40}
                minLimit={5}
              />
              <PasswordInput
                name="password"
                required
                placeholder="Password"
                value={form.password?.value as string}
                onChange={(e) => updateField('password', e.target.value)}
                valid={form.password?.valid}
                complexPassword
                characterLimit={40}
                minLimit={8}
              />
              <PasswordInput
                name="confirm_password"
                required
                placeholder="Re-enter password"
                value={form.confirm_password?.value as string}
                onChange={(e) => updateField('confirm_password', e.target.value)}
                valid={form.confirm_password?.valid}
                characterLimit={40}
                minLimit={8}
                showHint={true}
              />
              <Button text="Sign-Up" type="submit" className="rounded-[30px] text-[14px] py-[14px]" tracking />
            </Form>
            <SocialLogin initialValues={initialValues} />
          </div>
          <div className="text-[#111111] text-[12px] mt-[25px]">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
