import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Story, StoryId, UserId } from '@try-saga/storybuilder-iso';
import { generateHTML } from '../../utility/helpers';

export interface UploadStoryPayload {
  data: FormData;
  callback?: (id: string) => void;
}

interface SetStory extends Story {
  scriptContent: string;
}

export interface GetStoryPayload {
  id: string;
}

const initialState: SetStory = {
  id: StoryId.of(''),
  name: '',
  createdBy: UserId.of(''),
  scriptContent: '',
};

export interface GetStory {
  id: string;
  callback?: (id: string) => void;
}

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    setStory: (state, action: PayloadAction<SetStory>) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.createdBy = action.payload.createdBy;
      state.scriptContent = generateHTML(action.payload.scriptContent);
    },
    getStory: (state, action: PayloadAction<GetStoryPayload>) => {},
    uploadStory: (state, action: PayloadAction<UploadStoryPayload>) => {},
  },
});

export const { setStory, uploadStory, getStory } = storySlice.actions;
export const storyReducer = storySlice.reducer;
