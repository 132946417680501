/***
 *
 *   LABEL
 *   Form input label
 *   Props are passed from the input
 *
 *   PROPS
 *   for: name of the corresponding input (string, optional)
 *   text: label text (string, required)
 *
 **********/

import React from 'react';
import { classHelper } from '../../class';
import Style from './label.tailwind';
import { LabelProps } from '../formInterfaces';

export const Label: React.FC<LabelProps> = (props) => {
  const labelStyle = classHelper(Style);

  return <label className={labelStyle} htmlFor={props.htmlFor} dangerouslySetInnerHTML={{ __html: props.text }} />;
};
