import React from 'react';
import './spinner.scss';

interface SpinnerProps {
  color: string;
  width: number;
  height: number;
  backGroundColor?: string;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ color, width, height, backGroundColor, className }) => {
  return (
    <div
      className={className}
      style={{
        background: color,
        width: width,
        height: height,
      }}
    >
      <div
        className="w-full h-full rounded-full"
        style={{
          background: backGroundColor,
        }}
      ></div>
    </div>
  );
};
