import {
  ForgotPasswordPayload,
  InviteUserPayload,
  LoginPayload,
  SignupPayload,
  SocialLoginPayload,
  UpdatePayload,
  ValidateTokenPayload,
} from '../../redux/reducers/user';
import axios from './axiosConfig';

export const socialLoginApi = (payload: SocialLoginPayload) =>
  axios.post('/google-login', {
    access_token: payload.access_token,
  });

export const loginApi = (payload: LoginPayload) => axios.post('/login', payload.data);

export const signUpApi = (payload: SignupPayload) => axios.patch('/user/createUser', payload.data);

export const updateUserApi = (payload: UpdatePayload) => axios.patch('/user/updateUser', payload.data);

export const forgotPasswordApi = (payload: ForgotPasswordPayload) => axios.post('/user/forgotPassword', payload.data);

export const verifyTokenApi = (payload: ValidateTokenPayload) => axios.get('/user/verifyToken');

export const inviteUserApi = (payload: InviteUserPayload) =>
  axios.patch('/user/inviteUser', JSON.parse(payload.script));
