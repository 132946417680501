import React, { createContext, useContext, ReactNode } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import logger from '../logger';

interface DraggableContextType {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}

const DraggableContext = createContext<DraggableContextType | undefined>(undefined);

export const useDraggableContext = () => {
  const context = useContext(DraggableContext);
  if (!context) {
    logger.error('useDraggableContext must be used within a DraggableProvider');
    return {} as DraggableContextType;
  }
  return context;
};

interface DraggableProviderProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  children: ReactNode;
}

export const DraggableProvider: React.FC<DraggableProviderProps> = ({ provided, snapshot, children }) => {
  return (
    <DraggableContext.Provider value={{ provided: provided, snapshot: snapshot }}>{children}</DraggableContext.Provider>
  );
};
