import React from 'react';
import { Icon, Image } from '../lib';
import { FileInput } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';
import { FormDataFormField } from '../form/formInterfaces';
import { getFileUrl } from '../../utility/helpers';

interface UserAvatarProps {
  avatar: FormDataFormField['value'];
  isEdit: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  return (
    <div className="w-full">
      <FileInput
        accept="image/*"
        name="image"
        className={`group rounded-full w-[80px] h-[80px] flex justify-center items-center mx-auto 
        border border-black ${props.isEdit ? 'hover:border-opacity-50 cursor-pointer' : ''}`}
        isEdit={props.isEdit}
      >
        {props.isEdit ? (
          <>
            {!props.avatar && <Icon image="edit" pack="feather" className="group-hover:opacity-50" />}
            {props.avatar && (
              <Image
                className="object-cover rounded-full w-[80px] h-[80px]"
                src={getFileUrl(props.avatar) || (props.avatar as string)}
                alt="user-avatar"
              />
            )}
          </>
        ) : (
          <Image
            className="object-cover rounded-full w-full h-full"
            src={getFileUrl(props.avatar) || getCdnUrl('images/user.png')}
            alt="user-avatar"
          />
        )}
      </FileInput>
    </div>
  );
};

export default UserAvatar;
