import React, { useEffect, useState } from 'react';
import { Image } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';
import Script from './script';
import { AssetId, ImageMeta, KeyVisualId, ObjectProperty, Scene, SceneId } from '@try-saga/storybuilder-iso';
import Character from './character';
import KeyVisuals from './keyVisuals';
import { useNavigate, useParams } from 'react-router-dom';
import logger from '../../logger';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import axios from '../../config/api/axiosConfig';

interface ScriptSceneProps extends Scene {
  sceneIndex: number;
  properties: Record<string, ObjectProperty>;
  handleDeleteVisual: (index: number, sceneIndex: number) => void;
  handleAddVisual: (index: number, sceneIndex: number, id?: KeyVisualId) => void;
}

const ScriptScene: React.FC<ScriptSceneProps> = (props) => {
  const [showScript, setShowScript] = useState<boolean>(false);
  const [characterMapLoading, setCharacterMapLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [isDownload, setIsDownload] = useState<{ id: SceneId; load: boolean } | null>(null);

  const downloadScenes = async (sceneId?: SceneId) => {
    try {
      if (!sceneId) return;

      setIsDownload({ id: sceneId, load: true });
      const response = await axios.get(`/scene/downloadAllImageOfOneScene/${sceneId}`, {
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      if (contentType && contentType.includes('application/json')) {
        const reader = new FileReader();
        reader.onload = () => {
          const text = reader.result as string;
          const jsonResponse = JSON.parse(text);
          if (jsonResponse.message) {
            toast.error(jsonResponse.message);
          } else {
            toast.error('No images found');
          }
        };
        reader.readAsText(response.data);
      } else {
        // Handle blob response
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        /* Set the download filename */
        link.setAttribute('download', 'scenes.zip');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }

      setIsDownload(null);
    } catch (error) {
      logger.error(error);
      setIsDownload(null);

      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(String(error));
      }
    }
  };
  const [mappedCharacters, setMappedCharacters] = useState<
    Record<string, { assetId: AssetId; sceneId: SceneId; name: string; images: Record<string, ImageMeta> }>
  >({});

  useEffect(() => {
    const fetchMappedCharacters = async () => {
      try {
        setCharacterMapLoading(true);
        const response = await axios.get(`/scene/getMappedAssets/${props.id}`);
        const {
          data: { data },
        } = response;
        setMappedCharacters(data);
        setCharacterMapLoading(false);
      } catch (error) {
        logger.error(error);
        if (error instanceof AxiosError) {
          toast.error(error.response?.data.message);
        } else {
          toast.error(String(error));
        }
        setCharacterMapLoading(false);
      }
    };
    fetchMappedCharacters();
  }, [props.id]);

  return (
    <div>
      <div className="flex justify-between items-center max-h-[23px] ml-[30px] mr-[30px]">
        <div className="flex gap-x-[6px] items-center text-[#535457] rounded-[2.5px_4px_5.5px_2.5px] font-inter font-semibold py-[5px] w-fit">
          <p className="text-[#535457] text-[12px]">
            Scene {props.sceneIndex + 1} - {props.name.toUpperCase()}
          </p>
        </div>
        <div
          onClick={() => (isDownload?.id == props.id && isDownload?.load ? '' : downloadScenes(props.id))}
          className={`flex items-center gap-x-2 text-[${isDownload?.id == props.id && isDownload?.load ? '#111111' : '#535457'}] text-[12px] cursor-pointer`}
        >
          <Image
            src={getCdnUrl('icons/download.svg')}
            className="z-10 pointer-events-none"
            style={{ height: '14px' }}
            alt="ico-down"
          />
          <span>Scene Download</span>
        </div>
      </div>

      <div
        className="rounded-[10px] p-[1px] mt-[17px]"
        style={{
          background: 'linear-gradient(92.76deg, #c6c6c6 1.17%, #ededed 50%, #c6c6c6 100%)',
        }}
      >
        <div className="rounded-[10px] gap-y-[10px] bg-[#F0F0F0] flex flex-col px-[18px] py-[15px] relative">
          <div className="bg-white py-[10px] pl-[17px] rounded-[10px] flex gap-x-[50px]">
            <div className="flex flex-col gap-y-[11px] max-w-[900px] w-[100%]">
              <span className="text-[12px] text-[#7F8185]">Summary</span>
              <div className="bg-[#FAFAFA] rounded-[4px] border border-solid border-[#EBECEE] pt-[7px] pb-[10px] px-[18px] max-w-[900px] max-h-[85px] flex-1">
                <p className="font-poppins font-[300] leading-[18px] text-[12px] text-[#535454] py-[5px]">
                  {props.properties.summary.value as unknown as string}
                </p>
              </div>
            </div>
            <div className="flex-1 flex gap-x-[50px]">
              <div className="flex flex-col gap-x-[20px] flex-1">
                <p className="text-[#7F8185] font-inter font-semibold text-[12px]">Custom character models</p>
                <div className="flex justify-between">
                  <div className="w-[100%] flex lg:max-w-[140px] xl:max-w-[350px] overflow-x-scroll pb-[10px]">
                    {Array.isArray(props.properties?.characters?.value) &&
                      props.properties?.characters?.value?.map(
                        (
                          character: {
                            type: string;
                            value: {
                              name: { type: string; value: string };
                              description: { type: string; value: string };
                            };
                          },
                          index: number,
                        ) => {
                          return (
                            <Character
                              key={index}
                              loading={characterMapLoading}
                              image={
                                mappedCharacters[character?.value?.name?.value]?.images['face_base']?.sha256 ||
                                mappedCharacters[character?.value?.name?.value]?.images?.front_view?.sha256
                              }
                              name={character?.value?.name?.value}
                              action={() => {
                                navigate(`/kitchen`, { state: { character, storyId: id, sceneId: props.id } });
                              }}
                            />
                          );
                        },
                      )}
                  </div>
                  {/* We just hide the icon as suggested by Alex */}
                  {/* <div
                    className="flex flex-col cursor-pointer gap-y-[5px] h-full justify-around pb-[10px] mr-[17px]"
                    onClick={() => setShowScript(!showScript)}
                  >
                    <div className="w-[49px] h-[43.56px]">
                      <Image
                        src={getCdnUrl('icons/script.png')}
                        alt="script"
                        className="w-[49px] h-[43.56px] mt-[8px]"
                      />
                    </div>
                    <span className="text-[12px] font-[400] pl-[5px] mb-[2px]">Script</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <Script show={showScript} onClose={() => setShowScript(false)} />
          <KeyVisuals
            sceneId={props.id}
            keyVisuals={props.keyVisuals}
            sceneIndex={props.sceneIndex}
            handleDeleteVisual={props.handleDeleteVisual}
            handleAddVisual={props.handleAddVisual}
          />
        </div>
      </div>
    </div>
  );
};

export default ScriptScene;
