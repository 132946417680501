"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserId = void 0;
var UserId;
(function (UserId) {
    UserId.of = (id) => id;
})(UserId || (exports.UserId = UserId = {}));
;
;
;
