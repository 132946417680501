import { ObjectProperty, PropertiesType } from '@try-saga/storybuilder-iso';
import React, { useEffect, useMemo, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { unWrapCharacterNames, wrapCharacterNames } from '../../utility/helpers';

interface PromptInputProps {
  sceneIndex: number;
  properties: PropertiesType;
  handleChange: (name: string, value: string) => void;
}

const PromptInput: React.FC<PromptInputProps> = ({ sceneIndex, handleChange, properties }) => {
  const scenes = useSelector((state: RootState) => state.scenes?.scenes);
  const [content, setContent] = useState('');
  const [processedCharacters, setProcessedCharacters] = useState<string[]>([]);

  const characters = useMemo(
    () =>
      (scenes[sceneIndex].properties.characters.value as ObjectProperty[]).map((character) => {
        return { display: `@${character.value.name.value as string}`, id: character.value.name.value as string };
      }),
    [sceneIndex, scenes],
  );

  useEffect(() => {
    setProcessedCharacters(characters.map((character) => character.display.slice(1)));
  }, [characters]);

  const handleOnChange = (e: { target: { value: string } }) => {
    let updatedContent = e.target.value;

    /* Allow typing beyond 300 chars but trim safely */
    if (updatedContent.length > 300) {
      updatedContent = updatedContent.slice(0, 300);

      /* Ensure we don't leave an incomplete mention at the end */

      /* Detects partial mention at the end */
      const mentionRegex = /@\[[^\]]*\]\([^)]*$/;

      /* Detects double @@[ issue */
      const brokenMentionRegex = /@\[@[^]]*$/;

      if (mentionRegex.test(updatedContent) || brokenMentionRegex.test(updatedContent)) {
        /* Remove broken mention */
        updatedContent = updatedContent.replace(mentionRegex, '').replace(brokenMentionRegex, '');
      }
    }

    setContent(wrapCharacterNames(updatedContent, processedCharacters));
    handleChange('prompt', unWrapCharacterNames(updatedContent));
  };

  useEffect(() => {
    if (properties.prompt?.value) {
      setContent(wrapCharacterNames(properties.prompt!.value as string, processedCharacters));
    }
  }, [properties.prompt, processedCharacters]);

  return (
    <MentionsInput
      allowSpaceInQuery
      allowSuggestionsAboveCursor
      className="bg-[#FAFAFA] tracking-[2px] rounded-xl border border-solid border-[#EDEEEF] text-[12px] font-[300] p-3 font-poppins leading-[20px] text-[#111111] max-w-[387.5px] h-[166px]"
      value={content}
      onChange={handleOnChange}
      a11ySuggestionsListLabel={'Suggested mentions'}
    >
      <Mention
        trigger="@"
        appendSpaceOnAdd
        className="text-[#5533FF] relative z-10 pointer-events-none"
        style={{
          textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
          fontWeight: 300,
        }}
        renderSuggestion={(suggestion) => {
          return (
            <div className="py-[8px] cursor-pointer px-[16px] hover:text-[#5533FF] hover:bg-[#F5F4FF]">
              {suggestion.display?.slice(1)}
            </div>
          );
        }}
        data={characters}
      />
    </MentionsInput>
  );
};

export default PromptInput;
