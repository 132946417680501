/***
 *
 *   SELECT
 *   Dropdown select used within the form
 *   Props are passed from the form
 *
 *   PROPS
 *   className: custom styling (SCSS or tailwind style, optional)
 *   default: default selected option (string, optional)
 *   errorMessage: custom error message (string, optional)
 *   label: input label (string, optional)
 *   name: name of the input to be used as the label (string, required)
 *   onChange: callback function that executes on change (function, required)
 *   options: array of object options (array, required)
 *   required: this input is required (boolean, optional)
 *   valid: determines if the input is valid (boolean, required)
 *   value: initial value (string, optional)
 *   warning: warning highlight (boolean, optional)
 *
 **********/

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { classHelper } from '../../class';
import Style from './select.tailwind';
import { Label } from '../label/label';
import { global } from '../../../utility/messages/global';
import { useFormContext } from '../../../context/FormContext';
import { Image } from '../../lib';
import { getCdnUrl } from '../../../utility/cdnUtility';
import { Option } from '../formInterfaces';
import Options from './options';

interface SelectProps {
  className?: string;
  default?: string;
  errorMessage?: string;
  label?: string;
  name: string;
  onChange: (name: string, value: string, valid: boolean) => void;
  options: Option[];
  required?: boolean;
  valid?: boolean;
  value?: string;
  warning?: boolean;
  callback?: (value: string) => void;
  unselectText?: string;
}

export const Select: React.FC<SelectProps> = (props) => {
  const options = props.options;
  const { form } = useFormContext();
  const field = form[props.name] || { value: props.default || '', valid: undefined };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const ulRef = useRef<HTMLUListElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left: number; width: number }>({
    top: 0,
    left: 0,
    width: 0,
  });

  if (!props.default && options?.length) {
    if (options && options[0]?.value === 'unselected') options.shift();
    options.unshift({ value: 'unselected', label: props.unselectText || global.form.select.error });
  }

  const handleClickAndScroll = useCallback((event: Event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      !ulRef?.current?.contains(event.target as Node)
    ) {
      handleStateChange(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAndScroll);
    document.addEventListener('scroll', handleClickAndScroll, true);
    return () => {
      document.removeEventListener('mousedown', handleClickAndScroll);
      document.removeEventListener('scroll', handleClickAndScroll, true);
    };
  }, [handleClickAndScroll, isOpen]);

  const handleStateChange = (state: boolean) => {
    setIsOpen(state);

    if (state) {
      requestAnimationFrame(() => {
        if (buttonRef.current && dropdownRef.current && ulRef.current) {
          const rect = buttonRef.current.getBoundingClientRect();
          const dropdownRect = dropdownRef.current.getBoundingClientRect();
          const ulHeight = ulRef.current.offsetHeight;
          const spaceBelow = window.innerHeight - dropdownRect.bottom;
          const spaceAbove = dropdownRect.top;

          const isDropdownUp = spaceBelow < ulHeight && spaceAbove > spaceBelow;
          setDropdownPosition({
            top: isDropdownUp ? rect.top - ulHeight - 25 : rect.bottom + window.scrollY + 4,
            left: rect.left + window.scrollX,
            width: rect.width,
          });
        }
      });
    }
  };

  function change(value: string) {
    const selectedValue = value || 'unselected';
    let valid = false;
    valid = props.required && selectedValue === 'unselected' ? false : true;
    props.onChange(props.name, selectedValue, valid);
    handleStateChange(false);
    if (props.callback) {
      props.callback(selectedValue);
    }
  }

  const selectStyle = classHelper(Style, {
    select: true,
    error: field.valid === false,
    warning: props.warning,
  });

  const wrapperStyle = classHelper(Style, {
    className: props.className,
    success: field.valid === true,
    errorWrapper: field.valid === false,
    warningWrapper: props.warning,
  });

  const selectedOption =
    options.find((option) => {
      return option.value.toLowerCase() === (field.value as string).toLowerCase();
    }) || options[0];

  return (
    <div ref={dropdownRef}>
      {props.label && <Label text={props.label} htmlFor={props.name} />}
      <div className={wrapperStyle} ref={buttonRef}>
        <div className={selectStyle} onClick={() => handleStateChange(!isOpen)} id={props.name}>
          {selectedOption?.label}
        </div>
        <Image
          src={getCdnUrl('icons/ico-down.svg')}
          className="absolute z-10 top-[50%] right-[10px] pointer-events-none"
          style={{
            transform: 'translateY(-50%)',
          }}
          alt="ico-down"
        />
      </div>
      <Options options={options} isOpen={isOpen} ulRef={ulRef} dropdownPosition={dropdownPosition} change={change} />
    </div>
  );
};
