import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { setSuccess } from '../reducers/app';
import { GetStoryPayload, setStory, UploadStoryPayload } from '../reducers/story';
import { handleRequest } from './utils';
import { getStoryById, uploadStory } from '../../config/api/story';
import { StoryId } from '@try-saga/storybuilder-iso';

function* upload(action: PayloadAction<UploadStoryPayload>) {
  const response = yield* handleRequest(uploadStory, undefined, undefined, action.payload);

  if (response) {
    yield put(setSuccess(response.message));
    yield put(setStory(response.data));
    if (action.payload.callback) action.payload.callback(response.data.id);
  }
}

function* getStory(action: PayloadAction<GetStoryPayload>) {
  yield* handleRequest(getStoryById, (data) => setStory(data.data), undefined, StoryId.of(action.payload.id));
}

export function* storySagas() {
  yield takeLatest('story/uploadStory', upload);
  yield takeLatest('story/getStory', getStory);
}
