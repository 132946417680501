import React, { useEffect, useState } from 'react';
import { Button, Form, Image, Select, Spinner, TextInput } from '../lib';
import { useFormContext } from '../../context/FormContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createAsset, resetAsset, trainAsset } from '../../redux/reducers/assets';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { clearState } from '../../redux/reducers/app';
import { useTrackEvent } from '../../config/amplitude';
import { getEnvVariable } from '../../utility/helpers';
import ConfirmModal from '../confirm/confirmModel';

const CreateCharacter = () => {
  const { trackEvent } = useTrackEvent();
  const { form, setForm, updateField } = useFormContext();
  const location = useLocation();
  const { character } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appState = useSelector((state: RootState) => state.app);
  const asset = useSelector((state: RootState) => state.assets.asset);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmedModalOpen, setIsConfirmedModalOpen] = useState(false);
  const [loading, setLoading] = useState({
    generate: false,
    train: false,
  });

  useEffect(() => {
    dispatch(clearState());
    const characterValues = character?.value || {};
    const description = typeof characterValues.description?.value === 'string' ? characterValues.description.value : '';

    const initialValues = {
      name: { value: characterValues.name?.value || '', valid: undefined, required: true },
      type: { value: characterValues.type?.value || 'model type', valid: undefined, required: true },
      prompt: {
        value: description || '',
        valid: undefined,
        required: true,
      },
    };
    setForm({ ...initialValues });
    return () => {
      setForm({});
      setLoading({
        generate: false,
        train: false,
      });
      dispatch(clearState());
      dispatch(resetAsset());
    };
  }, [character?.value, dispatch, setForm]);

  const handleTrain = () => {
    dispatch(clearState());
    const eventName = asset.id ? 're-generate-character' : 'generate-character';
    trackEvent(eventName, { 'generate-character-button': 'home' });
    setLoading({
      train: true,
      generate: false,
    });
    const assetData = {
      assetId: asset.id,
      properties: {
        quality: getEnvVariable('REACT_APP_TRAINING_QUALITY') || 'test',
        class_tokens: form.prompt.value as string,
      },
      callback: () => {
        setLoading({
          ...loading,
          train: false,
        });
        setIsConfirmModalOpen(false);
        setIsConfirmedModalOpen(true);
      },
    };
    dispatch(trainAsset(assetData));
  };

  const handleTrainSuccess = () => {
    setIsConfirmedModalOpen(false);
    navigate(`/kitchen/?tab=new`);
  };

  const handleGenerate = () => {
    dispatch(clearState());
    const assetData = {
      assetName: form.name.value as string,
      properties: {
        prompt: form.prompt.value as string,
        type: form.type.value as string,
      },
      callback: () => {
        setLoading({
          ...loading,
          generate: false,
        });
      },
    };
    setLoading({
      train: false,
      generate: true,
    });
    dispatch(createAsset(assetData));
  };

  return (
    <div className="flex-1 flex gap-x-[30px]">
      <Form reset={false} className="gap-y-[10px] flex flex-col w-[378px]">
        <div>
          <span className="font-[400] text-[14px] leading-[17.6px]">
            Name
            {!form.name?.value && <sup>*</sup>}
          </span>
          <div className="flex gap-x-[10px] items-center">
            {character ? (
              <span className="min-w-[120px] font-poppins text-[16px] text-[#000000] ">
                {form.name?.value as string}
              </span>
            ) : (
              <TextInput
                className="font-poppins text-[12px] w-full max-w-[220px] text-[#000000] bg-transparent"
                valid={form.name?.valid}
                type="text"
                placeholder="Enter Character Name"
                name="name"
                required
                value={form.name?.value as string}
                onChange={(e) => updateField('name', e.target.value)}
                minLimit={3}
                characterLimit={40}
              />
            )}
            <Select
              name="type"
              required
              unselectText="Model Type"
              className="border h-[44px] flex-1 border-solid border-[#0000001A] rounded-[8px] font-poppins text-[12px] font-[500] min-w-[150px]"
              onChange={(name, value, valid) => updateField(name, value, valid)}
              options={[
                { label: 'Josei', value: 'josei' },
                { label: 'Seinin', value: 'seinin' },
                { label: 'Kawaii', value: 'kawaii' },
                { label: 'Kemonomimi', value: 'kemonomimi' },
              ]}
            />
          </div>
        </div>
        <span className="font-[400] text-[12px]">Describe your character</span>
        <div className="border-[2px] border-solid border-[#0000001A] p-[10px] rounded-[8px] relative">
          <TextInput
            className="font-poppins font-[600] text-[20px] text-[#000000] border-none bg-transparent h-[425px]"
            valid={form.prompt?.valid}
            type="textarea"
            placeholder="Describe your character"
            required
            name="prompt"
            onChange={(e) => updateField('prompt', e.target.value)}
            minLimit={3}
            characterLimit={250}
          />
          <div className="w-full text-right flex flex-wrap md:flex-nowrap justify-end">
            <Button
              text={asset.id ? 'Re-Generate' : 'Generate'}
              relative
              action={handleGenerate}
              flex
              loading={loading.generate}
              className="rounded-[8px] mt-[10px] font-[400] max-w-[160px] text-[14px] h-[28px] px-[12px]"
            />
            {asset.id && (
              <Button
                text="Train Model"
                border
                loading={loading.train}
                action={() => setIsConfirmModalOpen(true)}
                relative
                flex
                color="transparent"
                className="rounded-[8px] ml-[10px] font-[400] text-[14px] mt-[10px] max-w-[160px] h-[28px] px-[12px]"
              />
            )}
          </div>
          {isConfirmModalOpen && (
            <ConfirmModal
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
              message="Heads-up: Character model training is a credits intensive task."
              onConfirm={handleTrain}
              onCancel={() => setIsConfirmModalOpen(false)}
              isButtonEnable={true}
            />
          )}
          {isConfirmedModalOpen && (
            <ConfirmModal
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
              message="Your custom character model is being trained!  You can leave this page, we will notify you once it is ready for use. "
              onConfirm={() => {}}
              onCancel={handleTrainSuccess}
              isButtonEnable={false}
            />
          )}
        </div>
      </Form>
      <div
        className="p-[1px] flex-1 h-full max-h-[699px] rounded-[10px]"
        style={{
          background: 'linear-gradient(120.06deg, #C6C6C6 0%, #EDEDED 46%, #C6C6C6 100%)',
        }}
      >
        <div className="bg-[#F0F0F0] rounded-[10px] flex w-full h-full max-h-[699px]">
          <div className="m-auto w-full h-full flex justify-center items-center">
            {loading.generate && (
              <Spinner
                width={80}
                height={80}
                className="rounded-full p-[11.92px] animate-spin-reverse relative spinner"
                color="conic-gradient(from 180deg at 50% 50%, #6100FF 0deg, #6100FF 63.24deg, rgba(255, 255, 255, 0) 360deg)"
                backGroundColor="#F0F0F0"
              />
            )}
            {!loading.generate && appState.error ? (
              <span style={{ color: '#F00000' }} className="text-[14px]">
                Something went wrong. Please try again later
              </span>
            ) : null}
            {!loading.generate && Object.keys(asset.images || {}).length > 0 && (
              <Image
                alt="character-sheet"
                className="h-full w-full rounded-[10px]"
                src={asset.images['character_sheet']?.sha256}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCharacter;
