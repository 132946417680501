import { Temporal } from '@js-temporal/polyfill';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset, AssetId, UserId } from '@try-saga/storybuilder-iso';
import { AxiosResponse } from 'axios';

const initialAssetState: Asset = {
  id: AssetId.of(''),
  name: '',
  createdBy: UserId.of(''),
  properties: {},
  images: {},
  status: 'pending',
  createdAt: Temporal.Now.plainDateTimeISO(),
};

const initialState: { asset: Asset; assets: Asset[] } = {
  asset: initialAssetState,
  assets: [],
};

export interface GetAsset {
  id: string;
  callback?: (id: string) => void;
}

export interface CreateAsset {
  assetName: string;
  properties: Record<string, string | number>;
  callback?: (id: string) => void;
}

export interface TrainAsset {
  assetId: string;
  properties: Record<string, string | number>;
  callback?: () => void;
}

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    createAsset: (state, action: PayloadAction<CreateAsset>) => {},
    setAsset: (state, action: PayloadAction<AxiosResponse>) => {
      state.asset = action.payload.data;
    },
    trainAsset: (state, action: PayloadAction<TrainAsset>) => {},
    resetAsset: (state) => {
      state.asset = initialAssetState;
    },
    startPoll: (state, action: PayloadAction<GetAsset>) => {},
    setAssets: (state, action: PayloadAction<AxiosResponse>) => {
      state.assets = action.payload.data;
    },
    getAssets: (state, action: PayloadAction) => {},
  },
});

export const { createAsset, startPoll, trainAsset, setAsset, setAssets, getAssets, resetAsset } = assetsSlice.actions;

export const assetsReducer = assetsSlice.reducer;
