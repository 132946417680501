import { call, put, delay, CallEffect, PutEffect } from 'redux-saga/effects';
import { UnknownAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { stopLoading } from '../reducers/app';

/**
 * Generic polling saga for Redux Toolkit
 * @param apiCall - The API function to call for polling.
 * @param successAction - Redux Toolkit action to dispatch with the API response.
 * @param stopCondition - Function to check if polling should stop.
 * @param interval - Polling interval in milliseconds (default 5000 ms).
 */
export function* pollingSaga<T>(
  apiCall: () => Promise<AxiosResponse>,
  successAction: (response: AxiosResponse) => Generator<PutEffect | CallEffect, void, unknown>,
  stopCondition: (response: AxiosResponse) => boolean,
  interval = 5000,
  threshold = 600000,
  errorCallback: () => Generator<PutEffect | CallEffect, void, unknown>,
): Generator<CallEffect<AxiosResponse> | PutEffect<UnknownAction> | ReturnType<typeof delay>, void, AxiosResponse> {
  try {
    const startTime = new Date().getTime();
    while (true) {
      /* Call the API function */
      const response: AxiosResponse = yield call(apiCall);

      /* Check if the polling should stop */
      if (response.data.status === 'errored' || (threshold ? new Date().getTime() - startTime >= threshold : false)) {
        yield put(stopLoading());
        yield* errorCallback();
        break;
      }
      if (stopCondition(response)) {
        yield* successAction(response);
        yield put(stopLoading());
        break;
      }

      /* Wait for the polling interval */
      yield delay(interval);
    }
  } catch (error) {
    yield put(stopLoading());
    yield* errorCallback();
  }
}
