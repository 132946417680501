import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { DraggableProvider } from '../../context/DraggableContext';

interface DraggableItemProps {
  id: number;
  index: number;
  children: React.ReactNode;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ id, index, children }) => {
  return (
    <Draggable key={id} draggableId={String(id)} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <DraggableProvider provided={provided} snapshot={snapshot}>
          <div ref={provided.innerRef} {...provided.draggableProps} className="flex gap-x-[5px]">
            {children}
          </div>
        </DraggableProvider>
      )}
    </Draggable>
  );
};

export default DraggableItem;
