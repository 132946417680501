import { useEffect, useState } from 'react';

export const useCountDown = (start = false) => {
  const [countDown, setCountDown] = useState<number>(5);

  useEffect(() => {
    let progressInterval: number;
    if (start) {
      progressInterval = window.setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown <= 0) {
            return 0;
          }
          return prevCountDown - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(progressInterval);
    };
  }, [start]);

  return { countDown };
};
