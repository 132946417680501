import React from 'react';
import { Button, Card, Image } from '../components/lib';
import { getCdnUrl } from '../utility/cdnUtility';

const Home = () => {
  return (
    <div className="h-screen flex flex-col items-center mx-auto gap-3 xl:flex-row gap-x-5 ml-[47px] xl:-mt-[75px]">
      <div className="flex flex-col gap-4 max-w-[190px] font-fjalla text-center text-[12px] leading-[25px]">
        <h3>Welcome to Saga</h3>
        <div>
          <p>
            Hang tight, BETA phase has commenced, but we&apos;re still cooking. Stay tuned for updates and upcoming
            feature releases. Got thoughts? Want to join our team? We&apos;re listening, we&apos;re hiring, we want to
            build Saga together. Join our discord or @ us on X and let&apos;s talk.
          </p>
        </div>
        <div>
          <Button text="Discord" tracking className="max-w-[127px] text-[14px] rounded-[8px] py-[4px]" />
        </div>
        <div className="px-[14px]">
          <ul className="flex justify-between items-center">
            <li>
              <a href="https://www.youtube.com/@TrySaga" target="_blank" rel="noopener noreferrer">
                <Image src={getCdnUrl('icons/youtube.svg')} className="w-[34px] h-[24px]" alt="youtube" />
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@try.saga" target="_blank" rel="noopener noreferrer">
                <Image src={getCdnUrl('icons/tiktok.svg')} className="w-[34px] h-[24px]" alt="tiktok" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/trysaga_/" target="_blank" rel="noopener noreferrer">
                <Image src={getCdnUrl('icons/instagram.svg')} className="w-[34px] h-[24px]" alt="instagram" />
              </a>
            </li>
            <li>
              <a href="https://x.com/trysaga" target="_blank" rel="noopener noreferrer">
                <Image src={getCdnUrl('icons/twitter.svg')} className="w-[34px] h-[24px]" alt="twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex gap-y-3 gap-x-[60px] flex-col xl:flex-row mx-auto">
        <Card
          title="Character Kitchen"
          content="Design your characters once and keep them forever. Whip up endless new variations with flawless consistency"
          buttonText="Generate"
          image={getCdnUrl('images/card-1.svg')}
          imageRotation="left"
          buttonUrl="/kitchen"
        />
        <Card
          title="Saga Engine"
          content="Anime production for all. <br />
          Upload your script, story, or idea and bring your Saga to life."
          buttonText="Launch"
          image={getCdnUrl('images/card-2.svg')}
          imageRotation="right"
          buttonUrl="/script"
        />
      </div>
    </div>
  );
};

export default Home;
