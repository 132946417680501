import { call, put } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { startLoading, stopLoading, setError, setSuccess } from '../reducers/app';
import logger from '../../logger';
import { toast } from 'react-toastify';

/* Generic Request Handler */
export function* handleRequest<T, A extends unknown[]>(
  apiCall: (...args: A) => Promise<AxiosResponse<T>>,
  successAction?: (data: T) => { type: string; payload: T },
  errorAction?: () => void, // <-- Error callback
  ...args: A
): Generator<unknown, T | undefined, AxiosResponse<T>> {
  try {
    yield put(startLoading());
    const response: AxiosResponse<T> = yield call(apiCall, ...args);

    if (successAction) yield put(successAction(response.data));
    yield put(setSuccess());
    return response.data;
  } catch (e) {
    logger.error('Error:', e);
    toast.error(extractAxiosError(e));
    yield put(setError(extractAxiosError(e)));
    if (errorAction) errorAction();
  } finally {
    yield put(stopLoading());
  }
}

/* Extract Axios Error */
export const extractAxiosError = (e: unknown): string => {
  if (e instanceof AxiosError) {
    return e?.response?.data?.error || e?.response?.data || e?.response?.data?.message || 'Something went wrong';
  }
  return 'An unknown error occurred';
};
