import React from 'react';
import { Image } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';

interface CardProps {
  image: string;
  name: string;
  onClick: () => void;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div onClick={props.onClick} className="cursor-pointer">
      <Image src={props.image || getCdnUrl('icons/character.svg')} className="w-[158px] h-[158px]" alt={props.name} />
      <span className="text-[14px]">{props.name}</span>
    </div>
  );
};

export default Card;
