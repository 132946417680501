import { all, fork } from 'redux-saga/effects';
import { userSagas } from './userSaga';
import { storySagas } from './storySaga';
import { scenesSaga } from './scenesSaga';
import { assetsSaga } from './assetsSaga';

function* rootSaga() {
  yield all([fork(userSagas)]);
  yield all([fork(storySagas)]);
  yield all([fork(scenesSaga)]);
  yield all([fork(assetsSaga)]);
}

export default rootSaga;
