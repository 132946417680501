import React from 'react';
import { Image } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';
import { getEllipseForFile } from '../../utility/helpers';
import { useFormContext } from '../../context/FormContext';

interface FilePreviewProps {
  handleFileDelete: (name: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ handleFileDelete }) => {
  const { form } = useFormContext();

  return (
    <div
      className="p-[1px] rounded-2xl flex-1"
      style={{
        background: 'linear-gradient(104.66deg, #C6C6C6 0%, #EDEDED 50%, #C6C6C6 100%)',
      }}
    >
      <div className="h-full w-full rounded-2xl bg-[#F0F0F0] flex justify-center items-center">
        <div
          className="p-[1px] max-w-[298px] max-h-[140px] h-full w-full rounded-[10px]"
          style={{
            background: 'linear-gradient(146.56deg, #C6C6C6 0%, #EDEDED 50%, #C6C6C6 100%)',
          }}
        >
          <div className="w-full h-full bg-[#FFFDF8] rounded-[10px] pt-[7px] p-[10px]">
            <div className="h-[20] w-[20] text-right">
              <button onClick={(e) => handleFileDelete('script', e)}>
                <Image src={getCdnUrl('icons/x.svg')} alt="close" />
              </button>
            </div>
            <div className="mx-auto h-[69px] w-[185px]">
              <div className="flex gap-x-[30px] items-center">
                <div className="flex flex-col justify-center items-center">
                  <div className="w-[38px] h-[47.5px]">
                    <Image src={getCdnUrl('icons/file.svg')} alt="file" />
                  </div>
                  <p className="text-[12px] leading-[15.08px] text-[#111111] mt-[10.5px]">
                    {getEllipseForFile((form.script.value as File).name)}
                  </p>
                </div>
                <p className="text-[#0EAA00] font-[400] text-[16px] leading-[20.11px]">Upload Complete</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
