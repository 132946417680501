import React from 'react';
import Style from './input.tailwind';
import { classHelper } from '../../class/index';
import { Input, Label } from '../../lib';
import { PasswordInputProps } from '../formInterfaces';
import { useFormContext } from '../../../context/FormContext';
import './input.module.scss';

export const PasswordInput: React.FC<Omit<PasswordInputProps, 'type'>> = (props) => {
  const { form, setForm } = useFormContext();
  const field = form[props.name] || { value: '', valid: undefined };

  const passwordStyle = classHelper(Style, {
    textbox: true,
    className: props.className,
    error: field.valid === false,
  });

  function validate(value: string): boolean | undefined {
    /* Check if the value matches the password for confirm_password validation */
    const isConfirmPasswordValid = (value: string, confirmPassword?: string) => {
      return value === confirmPassword;
    };

    /* Check if the value is required and non-empty */
    const isRequiredValid = (value: string, required?: boolean) => {
      return !required || value !== '';
    };

    /* Check if the value meets the minimum length requirement */
    const isMinLimitValid = (value: string, minLimit?: number) => {
      return !(minLimit && value.trim().length < minLimit);
    };

    /* Check if the value meets the password complexity requirement (lowercase) */
    const isComplexPasswordValid = (value: string, complexPassword?: boolean): boolean => {
      if (complexPassword) {
        /* Regular expression to check:
        - At least 8 characters
        - At least one uppercase letter (A-Z)
        - At least one lowercase letter (a-z)
        - At least one number (0-9)
        - At least one special character (e.g., !@#$%^&*)
        */
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

        return passwordRegex.test(value);
      }

      return true; // If no complexPassword requirement, return true
    };

    const isValid =
      (props.name === 'confirm_password'
        ? isConfirmPasswordValid(value, form.password?.value as string)
        : isRequiredValid(value, props.required) &&
          isMinLimitValid(value, props.minLimit) &&
          isComplexPasswordValid(value, props.complexPassword)) &&
      /* Final check for confirm_password, re-checking if it's equal to the password */
      (props.name !== 'confirm_password' || value === form.password?.value);

    setForm((prevForm) => ({
      ...prevForm,
      [props.name]: { ...prevForm[props.name], value, valid: isValid },
    }));

    return isValid;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(value);
    props.onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) validate(e.target.value);
  };

  return (
    <div className={Style.input}>
      {props.label && <Label htmlFor={props.name} text={props.label} />}
      <Input
        id={props.name}
        name={props.name}
        type="password"
        placeholder={props.placeholder}
        value={field.value as string}
        onChange={handleChange}
        handleBlur={handleBlur}
        className={passwordStyle}
      />
      {props?.showHint && (
        <p
          className={`max-w-[330px] ml-[16px] mt-[20px] text-[10px] text-left color-[#111111] font-[400] opacity-[.5]`}
        >
          Please create a password of at least 8 characters that includes at least one uppercase letter (A–Z), one
          lowercase letter (a–z), one number (0–9), and one special character (e.g., !@#$%^&*)
        </p>
      )}
    </div>
  );
};
