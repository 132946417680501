import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Modal } from '../lib';

interface ScriptProps {
  show: boolean;
  onClose: () => void;
}

const Script: React.FC<ScriptProps> = ({ show, onClose }) => {
  const story = useSelector((state: RootState) => state.story);

  return (
    <Modal isOpen={show} onClose={onClose}>
      <div
        className="max-h-[590px] h-auto overflow-y-scroll"
        dangerouslySetInnerHTML={{ __html: story.scriptContent ?? '' }}
      ></div>
    </Modal>
  );
};

export default Script;
