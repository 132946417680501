import { combineReducers } from '@reduxjs/toolkit';
import { userReducer } from './user';
import { appReducer } from './app';
import { scenesReducer } from './scenes';
import { assetsReducer } from './assets';
import { storyReducer } from './story';

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  scenes: scenesReducer,
  assets: assetsReducer,
  story: storyReducer,
});

export default rootReducer;
