import React, { useEffect, useState } from 'react';
import { Button, Image, TextInput } from '../components/lib';
import { useDispatch } from 'react-redux';
import { getCdnUrl } from '../utility/cdnUtility';
import { clearState } from '../redux/reducers/app';
import { inviteUser } from '../redux/reducers/user';
import { ErrorResult, SuccessResult } from '@try-saga/storybuilder-iso';
import SampleScript from '../components/sendInvite/sampleScript';

const SendInvite = () => {
  const [script, setScript] = useState<string>('');
  const [scriptResult, setScriptResult] = useState<{ success: SuccessResult[]; error: ErrorResult[] } | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const validateJSON = (input: string): boolean => {
    try {
      const parsed = JSON.parse(input);

      if (!Array.isArray(parsed)) {
        setErrorMessage('Invalid format: Input is not an array.');
        return false;
      }

      const isValid = parsed.every((item) => ['first_name', 'last_name', 'email'].every((field) => field in item));

      if (!isValid) {
        setErrorMessage('Invalid format: Missing required fields.');
        return false;
      }

      setErrorMessage(null);
      return true;
    } catch (err) {
      setErrorMessage('Invalid format: Input is not valid JSON.');
      return false;
    }
  };

  const handleSubmit = () => {
    if (validateJSON(script)) {
      dispatch(
        inviteUser({
          script: script,
          callback: (inviteResult) => {
            setScriptResult(inviteResult);
            setScript('');
          },
        }),
      );
    }
  };

  return (
    <>
      <div className="h-full p-5 flex flex-col">
        <div className="mt-6 ml-12">
          <h3 className="mb-8 text-xl leading-[30.16px]">Send Invite</h3>
        </div>

        <div className="flex flex-col lg:flex-row rounded-ss-[20px] h-full w-full">
          <div className="w-full lg:w-2/3 pr-5 flex flex-col gap-y-3 max-h-[360px] overflow-hidden flex-1">
            <TextInput
              type="textarea"
              name="script"
              characterLimit={20000}
              minLimit={50}
              onChange={(e) => setScript(e.target.value)}
              required
              value={script as string}
              className="h-full rounded-2xl text-sm border border-solid flex-1"
              placeholder="Write invite user script here..."
            />

            <div className="mt-10 mb-14 flex items-center justify-end gap-x-2">
              {errorMessage && (
                <div className="flex max-w-[400px] items-center justify-center gap-x-2 py-2">
                  <div className="w-5 h-4">
                    <Image src={getCdnUrl('icons/error.svg')} alt="error" className="object-cover h-full w-full" />
                  </div>
                  <span className="text-black text-sm leading-[12.5px]">{errorMessage}</span>
                </div>
              )}
              <Button
                text="Smart Upload"
                flex
                relative
                className="max-w-[167px] rounded-lg py-2 px-6 text-sm"
                color="blue"
                action={handleSubmit}
              />
            </div>
          </div>

          <SampleScript />
        </div>
        <div className="w-full p-4">
          {/* Success section */}
          {scriptResult && scriptResult.success.length > 0 && (
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-green-600 mb-4">Successfully Created Users</h2>
              <ul className="space-y-3">
                {scriptResult.success.map((user, index) => (
                  <li key={index} className="p-4 bg-green-100 border-l-4 border-green-500 rounded-lg">
                    <p className="text-sm font-medium">
                      {user.first_name} {user.last_name}
                    </p>
                    <p className="text-sm">Email: {user.email}</p>
                    <p className="text-sm text-green-700">Message: {user.message}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Error section */}
          {scriptResult && scriptResult.error.length > 0 && (
            <div>
              <h2 className="text-lg font-semibold text-red-600 mb-4">Failed Invitations</h2>
              <ul className="space-y-3">
                {scriptResult.error.map((user, index) => (
                  <li key={index} className="p-4 bg-red-100 border-l-4 border-red-500 rounded-lg">
                    <p className="text-sm font-medium">
                      {user.first_name} {user.last_name}
                    </p>
                    <p className="text-sm">Email: {user.email}</p>
                    <p className="text-sm text-red-700">Error: {user.error}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SendInvite;
