import React from 'react';
import { Spinner } from '../lib';
import { useCountDown } from '../../hooks/useCountDown';
import { useNavigate } from 'react-router-dom';

interface ScenesSkeletonProps {
  text?: string;
  subHeading?: string;
  success?: boolean;
  redirect?: boolean;
}

const ScenesSkeleton: React.FC<ScenesSkeletonProps> = ({ text, subHeading, success, redirect }) => {
  const navigate = useNavigate();
  const { countDown } = useCountDown(!success);
  if (countDown <= 0) navigate(0);

  return (
    <div className="py-[30px] px-[20px] flex flex-col gap-y-[17px]">
      <div className="w-[283px] clip-path flex items-center justify-center h-[23px] gap-0 rounded-[2.5px_4px_5.5px_2.5px] border-[3px_0px_0px_0px] bg-[#F4F5F6]">
        <div className="inner-clip !h-[93%] !rounded-[2.5px_4px_5.5px_2.5px]"></div>
      </div>
      <div className="flex-1 p-[5px]">
        <div className="w-full h-[719px] gap-y-[10px] rounded-[10px] bg-[#F0F0F0] px-[18px] py-[15px] flex flex-col">
          <div className="clip-path flex items-center justify-center h-[127px] w-full rounded-[10px]">
            <div className="inner-clip full-linear"></div>
          </div>
          <div className="flex-1 flex gap-x-[23px] overflow-x-hidden">
            <div className="clip-path rounded-[10px] h-full w-full max-w-[556px] flex items-center justify-center">
              <div className="inner-clip"></div>
            </div>
            <div className="clip-path rounded-[10px] h-full w-full max-w-[556px] flex items-center justify-center">
              <div className="inner-clip flex flex-col gap-y-4 justify-center items-center">
                {success && (
                  <Spinner
                    width={48}
                    height={48}
                    className="rounded-full p-[6px] animate-spin-reverse relative spinner-small"
                    color="conic-gradient(from 180deg at 50% 50%, #6100FF 0deg, #6100FF 63.24deg, rgba(255, 255, 255, 0) 360deg)"
                    backGroundColor="#F0F0F0"
                  />
                )}
                <div className="text-center">
                  {text && (
                    <p
                      style={{ color: success ? '#000000' : '#F00000' }}
                      className="text-[25px] font-fjalla leading-[40px]"
                    >
                      {text}
                    </p>
                  )}

                  {subHeading && (
                    <span style={{ color: success ? '#000000' : '#F00000' }} className="text-[14px]">
                      {subHeading}
                    </span>
                  )}
                </div>
                {!success && redirect && (
                  <p className="text-[12px]">
                    Redirecting in {countDown} sec.{' '}
                    <span
                      className="text-[#11111180] font-[400] cursor-pointer"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Redirect now?
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="clip-path rounded-[10px] h-full w-full max-w-[556px] flex items-center justify-center">
              <div className="inner-clip"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenesSkeleton;
