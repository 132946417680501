import React from 'react';
import { Image } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';

interface CharacterProps {
  name: string;
  image?: string;
  action: () => void;
  loading?: boolean;
}

const Character: React.FC<CharacterProps> = (props) => {
  return props.loading ? (
    <div className="flex flex-col cursor-pointer max-w-[90px] w-[70px] h-[90px]"></div>
  ) : (
    <div className="flex flex-col cursor-pointer max-w-[90px]" onClick={props.action}>
      <div className="text-center items-center p-[5px] flex flex-col gap-y-[5px]">
        <div className="rounded-[10px] w-[60px] h-[60px] flex justify-center items-center">
          <Image
            src={props.image || getCdnUrl('icons/character.svg')}
            alt={props.name}
            className="w-[60px] h-[50px] rounded-[10px]"
          />
        </div>
        <span className="text-[12px] break-word text-[#111111] font-[300] leading-[18px] font-poppins text-center">
          {props.name}
        </span>
      </div>
    </div>
  );
};

export default Character;
