/***
 *
 *   CLASS HELPER
 *   Helper function for applying SCSS or Tailwind classes
 *
 *   PARAMS
 *   debug: log the output to the console (boolean, optional)
 *   props: props object (object, required)
 *   styles: SCSS or tailwind styles (required)
 *
 **********/

import logger from '../../logger';

type Styles = {
  base?: string;
  [key: string]: string | undefined;
};

type Props = {
  className?: string;
  [key: string]: string | boolean | object | number | undefined;
};

export function classHelper(styles: Styles, props?: Props, debug?: boolean): string {
  let str = '';

  if (typeof styles === 'object') {
    if (styles.base && (!props || !('base' in props))) {
      /* always apply base styles */
      str += styles.base;
    }

    if (props != null && Object.keys(props).length) {
      for (const prop of Object.keys(props)) {
        if (prop === 'className' && props[prop]) {
          /* always apply className and base */
          str = str.trim();
          str += ` ${props[prop]}`;
        } else if (props[prop] && Object.prototype.hasOwnProperty.call(styles, prop)) {
          if (debug) {
            logger.info('styles', prop, props[prop], styles[prop]);
          }

          str = str.trim();
          str += ` ${styles[prop]}`;
        }
      }
    }
  }

  if (debug) {
    logger.info('final.class', str.trim());
  }

  return str.trim();
}
