export const global = {
  form: {
    email: {
      error: 'Please enter correct email address',
    },
    text: {
      error: 'Please enter a value',
    },
    password: {
      error: {
        unentered: 'Please enter a password',
        min: 'Password must be a minimum of 8 characters',
        uppercase: 'Password must have at least one upper case letter',
        lowercase: 'Password must have at least one lower case letter',
      },
    },
    select: {
      error: 'Please Select an option',
    },
  },
};
