import {
  ImageMeta,
  KeyVisual,
  KeyVisualId,
  PropertiesType,
  PropertyTypeEnum,
  Scene,
  SceneId,
  UserId,
} from '@try-saga/storybuilder-iso';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { DroppableItemType } from '../../components/dragndrop/droppable';
import { UserState } from './user';

interface SceneState {
  scenes: Scene[];
}

const initialState: SceneState = {
  scenes: [],
};

interface NewKeyVisual {
  index: number;
  sceneIndex: number;
  user: UserState;
}

export interface DeleteKeyVisual extends Omit<NewKeyVisual, 'user'> {
  id?: KeyVisualId;
  callback?: () => void;
}

export interface SetVisuals {
  sceneIndex: number;
  visuals?: DroppableItemType[];
}

export interface UpdateKeyVisualOrder {
  sceneIndex: number;
  visuals?: KeyVisual[];
}

const newKeyVisual: KeyVisual = {
  id: KeyVisualId.of(''),
  createdBy: UserId.of(''),
  properties: {
    prompt: {
      type: PropertyTypeEnum.String,
      value: '',
    },
    setting: {
      type: PropertyTypeEnum.String,
      value: '',
    },
    shotType: {
      type: PropertyTypeEnum.String,
      value: '',
    },
  },
  images: {},
  parentId: SceneId.of(''),
  order: 0,
};

export interface GetScenes {
  id: string;
  callback?: (id: string) => void;
}

export interface GenerateKeyVisual {
  data: {
    sceneId?: string;
    keyVisualId?: KeyVisualId;
    keyVisualProperties: PropertiesType;
    sceneIndex: number;
  };
  visuals?: KeyVisual[];
  callback: () => void;
}

export interface GetKeyVisual {
  id: KeyVisualId;
  sceneIndex: number;
  callback: () => void;
}

export interface UpdateKeyVisual {
  keyVisual: KeyVisual;
  sceneIndex: number;
}

const scenesSlice = createSlice({
  name: 'scenes',
  initialState,
  reducers: {
    setScenesData: (state, action: PayloadAction<AxiosResponse>) => {
      state.scenes = action.payload.data;
    },
    setKeyVisuals: (state, action: PayloadAction<SetVisuals>) => {
      const sceneIndex = action.payload.sceneIndex;
      if (action.payload.visuals) {
        const keyVisuals = action.payload.visuals;
        state.scenes[sceneIndex].keyVisuals = keyVisuals?.map((visual, index) => {
          const keyVisual: KeyVisual = {
            ...visual,
            createdBy: UserId.of(visual.createdBy as string),
            images: visual.images as Record<string, ImageMeta>,
            properties: visual.properties as PropertiesType,
            parentId: SceneId.of(visual.parentId as string),
            order: index + 1,
          };
          return keyVisual;
        });
      }
    },
    addNewKeyVisual: (state, action: PayloadAction<NewKeyVisual>) => {
      const index = action.payload.index;
      const sceneIndex = action.payload.sceneIndex;
      const keyVisuals = state.scenes[sceneIndex].keyVisuals;
      if (keyVisuals) {
        keyVisuals.splice(index + 1, 0, {
          ...newKeyVisual,
          order: index + 2,
          id: KeyVisualId.of(''),
          parentId: SceneId.of(state.scenes[sceneIndex].id || ''),
          createdBy: action.payload.user.id,
        });
        for (const index in keyVisuals) {
          keyVisuals[index].order = parseInt(index) + 1;
        }
        state.scenes[sceneIndex].keyVisuals = keyVisuals;
      }
    },
    deleteKeyVisual: (state, action: PayloadAction<DeleteKeyVisual>) => {
      const index = action.payload.index;
      const sceneIndex = action.payload.sceneIndex;
      const keyVisuals = state.scenes[sceneIndex].keyVisuals || [];
      keyVisuals.splice(index, 1);
      state.scenes[sceneIndex].keyVisuals = keyVisuals;
    },
    clearScenes: (state) => {
      state.scenes = [];
    },
    updateApiKeyVisuals: (state, action: PayloadAction<UpdateKeyVisualOrder>) => {},
    getScene: (state, action: PayloadAction<GetScenes>) => {},
    startScenePoll: (state, action: PayloadAction<GetScenes>) => {},
    startKeyVisualPoll: (state, action: PayloadAction<GetKeyVisual>) => {},
    generateKeyVisual: (state, action: PayloadAction<GenerateKeyVisual>) => {},
    handleUpdateKeyVisuals: (state, action: PayloadAction<SetVisuals>) => {},
    updateKeyVisual: (state, action: PayloadAction<UpdateKeyVisual>) => {
      const sceneIndex = action.payload.sceneIndex;
      const keyVisual = action.payload.keyVisual;
      const keyVisuals = state.scenes[sceneIndex].keyVisuals || [];
      const index = keyVisuals.findIndex((obj) => obj.id === keyVisual.id);
      const updatedKeyVisuals = [...keyVisuals];
      updatedKeyVisuals[index] = {
        ...updatedKeyVisuals[index],
        images: keyVisual.images,
      };

      const updatedScenes = [...state.scenes];
      updatedScenes[sceneIndex] = {
        ...state.scenes[sceneIndex],
        keyVisuals: updatedKeyVisuals,
      };

      return {
        ...state,
        scenes: updatedScenes,
      };
    },
  },
});

export const {
  setScenesData,
  getScene,
  deleteKeyVisual,
  addNewKeyVisual,
  startScenePoll,
  startKeyVisualPoll,
  setKeyVisuals,
  updateApiKeyVisuals,
  generateKeyVisual,
  updateKeyVisual,
  handleUpdateKeyVisuals,
  clearScenes,
} = scenesSlice.actions;

export const scenesReducer = scenesSlice.reducer;
