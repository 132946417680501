import React from 'react';
import Style from './input.tailwind';
import { classHelper } from '../../class/index';
import { Input, Label } from '../../lib';
import { EmailInputProps } from '../formInterfaces';
import { useFormContext } from '../../../context/FormContext';
import './input.module.scss';

export const EmailInput: React.FC<Omit<EmailInputProps, 'type'>> = (props) => {
  const { form, setForm } = useFormContext();
  const field = form[props.name] || { value: '', valid: undefined };

  const emailStyle = classHelper(Style, {
    textbox: true,
    className: props.className,
    error: field.valid === false,
  });

  function validate(value: string): void {
    const isRequiredValid = (value: string, required?: boolean) => {
      return !required || value !== '';
    };

    /* Function to check the minimum length requirement */
    const isMinLimitValid = (value: string, minLimit?: number) => {
      return !(minLimit && value.length < minLimit);
    };

    /* Function to check if the value is a valid email */
    const isEmailValid = (value: string) => {
      /* This regular expression checks if the input string is a valid email format.
      It ensures that the string follows a basic email structure: 
      1. The local part (before the "@") must be valid, allowing alphanumeric characters and some special symbols.
      2. The domain part (after the "@") must be a valid domain with alphanumeric characters and periods, or an IP address.
      The function returns true if the value is an empty string (allowing optional email) or matches the regex. */

      const rex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return value === '' || rex.test(value);
    };

    /* Combine all checks into one final validation result */
    const isValid =
      isRequiredValid(value, props.required) && isMinLimitValid(value, props.minLimit) && isEmailValid(value);

    /* Update form state without mutating the input */
    setForm((prevForm) => ({
      ...prevForm,
      [props.name]: { ...prevForm[props.name], value, valid: isValid },
    }));
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(value);
    props.onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) validate(e.target.value);
  };

  return (
    <div className={props.className}>
      {props.label && <Label text={props.label} htmlFor={props.name} />}
      <Input
        id={props.name}
        name={props.name}
        type="email"
        placeholder={props.placeholder}
        value={field.value as string}
        onChange={handleChange}
        handleBlur={handleBlur}
        className={emailStyle}
      />
    </div>
  );
};
