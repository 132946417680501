import React, { useEffect, useState } from 'react';
import { Button, Image } from '../components/lib';
import { getCdnUrl } from '../utility/cdnUtility';
import Characters from '../components/kitchen/characters';
import CreateCharacter from '../components/kitchen/createCharacter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBlockRouteChange } from '../utility/routeGuard';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { cancelConfirmPopup } from '../redux/reducers/app';

const Kitchen = () => {
  const location = useLocation();
  const { navigateWithConfirmation } = useBlockRouteChange();
  const isConfirm = useSelector((state: RootState) => state.app.isConfirmed);
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<string>(tab || 'existing');
  const [reqTab, setReqTab] = useState<string>(tab || '');
  const navigate = useNavigate();
  const { storyId } = location.state || {};

  const switchTab = async (tab: string) => {
    if (activeTab === 'new' && tab !== activeTab) {
      const isSwitchable = navigateWithConfirmation();
      if (!isSwitchable) {
        setReqTab(tab);
        return;
      }
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    if (isConfirm) {
      setActiveTab(reqTab);
      dispatch(cancelConfirmPopup());
    }
  }, [isConfirm, dispatch, reqTab]);

  const handleActiveTabChange = (active: string) => {
    setActiveTab(active);
  };

  return (
    <div className="mt-[20px] ml-[30px] xl:ml-[51px] mr-[20px] xl:mr-[43px] flex gap-x-[23px] flex-1 max-h-[590px] xl:max-h-[750px] h-full">
      <div
        className="p-[1px] max-h-[170px] mt-[37px] rounded-[10px]"
        style={{
          background: 'linear-gradient(175.5deg, #C6C6C6 0%, #EDEDED 50%, #C6C6C6 100%)',
        }}
      >
        <div className="w-full h-full max-h-[170px] rounded-[10px] py-[10px] px-[10px] bg-[#F0F0F0] flex flex-col gap-y-[10px] justify-center items-center max-w-[120px]">
          <div
            className="rounded-[5px] w-[100px] h-[65px] flex flex-col justify-center items-center gap-y-[5px] px-[9px] py-[2px] cursor-pointer"
            onClick={() => {
              switchTab('existing');
            }}
            style={{
              background: activeTab === 'existing' ? '#6100FF80' : '',
            }}
          >
            <div className="h-[40px] w-[35px] pt-[3px]">
              <Image src={getCdnUrl('icons/character.svg')} alt="character" />
            </div>
            <span className="text-[12px] font-[400] text-[#111111]">Character Models</span>
          </div>
          <div
            onClick={() => {
              switchTab('new');
            }}
            style={{
              background: activeTab === 'new' ? '#6100FF80' : '',
            }}
            className="rounded-[5px] w-[100px] py-[2px] h-[65px] flex flex-col justify-center items-center gap-y-[5px] cursor-pointer"
          >
            <div className="h-[40px] w-[35px] pt-[3px]">
              <Image src={getCdnUrl('/icons/create_character.svg')} alt="create" />
            </div>
            <span className="text-[12px] font-[400] text-[#111111]">Create New</span>
          </div>
        </div>
      </div>

      {activeTab === 'existing' ? <Characters handleActiveTabChange={handleActiveTabChange} /> : <CreateCharacter />}

      {storyId && (
        <div className="max-w-[174px]">
          <Button
            text="Back to Story"
            border
            flex
            icon={getCdnUrl('icons/x.svg')}
            color="transparent"
            className="text-[12px] px-[11px] rounded-[6px] border-[#000000] border-opacity-50 max-w-[107px] max-h-[32px] h-full font-[400] leading-[22px]"
            action={() => navigate(`/story/${storyId}`)}
          />
        </div>
      )}
    </div>
  );
};

export default Kitchen;
