import { KeyVisualId, StoryId } from '@try-saga/storybuilder-iso';
import axios from '../../config/api/axiosConfig';
import { GenerateKeyVisual, SetVisuals } from '../../redux/reducers/scenes';

export const fetchScenesForStory = async (id: StoryId) => axios.get(`/scene/fetchScenesForStory/${id}`);

export const fetchKeyVisualById = async (id: KeyVisualId) => axios.get(`/scene/getKeyVisualById/${id}`);

export const deleteKeyVisualById = async (id: KeyVisualId) => axios.delete(`/scene/deleteKeyVisual/${id}`);

export const updateKeyVisualsApi = async (keyVisuals: SetVisuals) =>
  axios.post('/scene/updateKeyVisuals', { keyVisuals: keyVisuals.visuals });

export const generateKeyVisualImage = async (data: GenerateKeyVisual) =>
  axios.post('/scene/generateKeyVisualImage', data.data);
