import { default as Axios, AxiosInstance } from 'axios';
import store from '../../redux/store';
import { stopLoading } from '../../redux/reducers/app';
import { deleteUser } from '../../redux/reducers/user';
import { toast } from 'react-toastify';
import { getEnvVariable } from '../../utility/helpers';
import logger from '../../logger';

const baseURL = getEnvVariable('NODE_ENV') === 'development' ? 'http://localhost:3001/api/v1' : '/api/v1';

const axios: AxiosInstance = Axios.create({
  baseURL: baseURL,
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token') || '';
    if (token && config.headers) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }

    const resetToken = localStorage.getItem('resetToken') || '';
    if (resetToken && config.headers) {
      config.headers.set('Authorization', `Bearer ${resetToken}`);
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    logger.error(error);

    if (error.status === 401) {
      toast.error('Session Expired! Please login again');
      localStorage.removeItem('token');
      store.dispatch(stopLoading());
      store.dispatch(deleteUser());
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } else if (error.code === 'ERR_NETWORK') {
      toast.error(`Network error.`);
    }

    return Promise.reject(error);
  },
);

export default axios;
