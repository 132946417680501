import React from 'react';
import { Option } from '../formInterfaces';
import { createPortal } from 'react-dom';

interface OptionsProps {
  options: Option[];
  isOpen: boolean;
  ulRef: React.RefObject<HTMLUListElement | null>;
  dropdownPosition: { top: number; left: number; width: number };
  change: (value: string) => void;
}

const Options: React.FC<OptionsProps> = ({ isOpen, ulRef, options, dropdownPosition, change }) => {
  if (!isOpen) return null;

  return (
    isOpen &&
    createPortal(
      <div
        className="absolute z-20 py-[8px] bg-[#FFFDF8] shadow-lg rounded-[8px] border border-[#0000001A]"
        style={{ top: dropdownPosition.top, left: dropdownPosition.left, width: dropdownPosition.width }}
      >
        <ul
          ref={ulRef}
          className="min-w-[150px] font-poppins font-[400] text-[12px] max-h-[150px] overflow-y-auto leading-[20px]"
        >
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => change(option.value)}
              className="pl-[16px] hover:bg-[#6100ff12] pr-[13px] py-[8px] last:mb-[8px] cursor-pointer"
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>,
      document.body,
    )
  );
};

export default Options;
