import React, { useState } from 'react';
import Card from './card';
import { Button, Image, Modal } from '../lib';
import { getCdnUrl } from '../../utility/cdnUtility';
import { Asset } from '@try-saga/storybuilder-iso';
import { useLocation, useNavigate } from 'react-router-dom';
import logger from '../../logger';
import axios from '../../config/api/axiosConfig';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface CharacterListProps {
  title: string;
  characters: Asset[];
}

interface ModalState {
  status: boolean;
  index: number;
}

const CharacterList: React.FC<CharacterListProps> = (props) => {
  const [isModalOpen, setModalOpen] = useState<ModalState>({
    status: false,
    index: 0,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { character, sceneId, storyId } = location.state || {};
  const handleModelChange = (value: boolean, index?: number) => {
    setModalOpen({ status: value, index: index || 0 });
  };

  const handleSet = async () => {
    const asset: Asset = props.characters[isModalOpen.index];
    const data = {
      assetId: asset.id,
      sceneId,
      name: character?.value.name.value,
    };

    try {
      const response = await axios.post('/scene/mapAssetWithScene', data);
      const {
        status,
        data: { message },
      } = response;
      if (status === 200) {
        toast.success(message);
        navigate(`/story/${storyId}`);
      }
    } catch (error) {
      logger.error(error);
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(String(error));
      }
    }
  };

  const CharacterModal = () => {
    const asset: Asset = props.characters[isModalOpen.index];
    return (
      <Modal isOpen={isModalOpen.status} onClose={handleModelChange}>
        <div className="mt-[5px] flex flex-col gap-y-[20px]">
          <div className="flex gap-x-[12px] justify-between">
            <div className="flex flex-col gap-y-[10px] min-w-[200px] max-w-[300px]">
              <h3 className="text-[#5533FF] text-[12px] font-[500] font-fira">{asset.name}</h3>
              <span className="text-[14px] font-[600] font-poppins leading-[20px]">
                {asset.properties.prompt.value as string}
              </span>
            </div>
            <div className="w-[320px] h-[320px] rounded-[10px]">
              <Image
                src={
                  asset.images['face_base']?.sha256 ||
                  asset.images?.front_view?.sha256 ||
                  getCdnUrl('icons/character.svg')
                }
                alt="character-modal"
                className="w-full h-full"
              />
            </div>
          </div>
          {character != null ? (
            <div className="w-full text-center">
              <Button
                action={handleSet}
                text={`Set to ${character?.value.name.value}`}
                className="rounded-[8px] text-[14px] font-[400] text-[#FFFDF8] py-[7px] max-w-[483px]"
              />
            </div>
          ) : undefined}
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-y-[15px] overflow-y-auto">
        <h3 className="font-[400] text-[20px] mt-[35px]">{props.title}</h3>
        {props.characters?.length ? (
          <div className="flex gap-x-[20px] flex-wrap text-center mb-[35px] gap-y-[20px]">
            {props.characters.map((character: Asset, index: number) => {
              return (
                <Card
                  key={index}
                  name={character.name}
                  image={character.images['face_base']?.sha256 || character.images?.front_view?.sha256}
                  onClick={() => handleModelChange(true, index)}
                />
              );
            })}
          </div>
        ) : undefined}
      </div>
      {isModalOpen.status && <CharacterModal />}
    </>
  );
};

export default CharacterList;
