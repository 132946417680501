"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListProperty = exports.ObjectProperty = exports.PropertyTypeEnum = exports.PropertyType = void 0;
const t = __importStar(require("io-ts"));
exports.PropertyType = t.recursion('NestedObjectProperty', () => t.union([StringProperty, NumberProperty, BooleanProperty, exports.ObjectProperty, exports.ListProperty]));
var PropertyTypeEnum;
(function (PropertyTypeEnum) {
    PropertyTypeEnum["String"] = "string";
    PropertyTypeEnum["Number"] = "number";
    PropertyTypeEnum["Boolean"] = "boolean";
    PropertyTypeEnum["Object"] = "object";
    PropertyTypeEnum["List"] = "list";
})(PropertyTypeEnum || (exports.PropertyTypeEnum = PropertyTypeEnum = {}));
const StringProperty = t.type({
    type: t.literal(PropertyTypeEnum.String),
    value: t.string,
}, 'StringProperty');
const NumberProperty = t.type({
    type: t.literal(PropertyTypeEnum.Number),
    value: t.number,
}, 'NumberProperty');
const BooleanProperty = t.type({
    type: t.literal(PropertyTypeEnum.Boolean),
    value: t.boolean,
}, 'BooleanProperty');
exports.ObjectProperty = t.recursion('ObjectProperty', () => t.type({
    type: t.literal(PropertyTypeEnum.Object),
    value: t.record(t.string, exports.PropertyType),
}));
exports.ListProperty = t.recursion('ListProperty', () => t.type({
    type: t.literal(PropertyTypeEnum.List),
    value: t.array(exports.PropertyType),
}));
