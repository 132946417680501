import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Button, Form, PasswordInput, TextInput } from '../../components/lib';
import UserAvatar from './userAvatar';
import { useFormContext } from '../../context/FormContext';
import { update } from '../../redux/reducers/user';
import { useDispatch } from 'react-redux';
import { getCdnUrl } from '../../utility/cdnUtility';
import { FormDataFormField } from '../form/formInterfaces';
import { clearState } from '../../redux/reducers/app';
import ConfirmModal from '../confirm/confirmModel';

const UserInfo = () => {
  const user = useSelector((state: RootState) => state.user);
  const appState = useSelector((state: RootState) => state.app);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [passwordEnabled, setPasswordEnabled] = useState<boolean>(false);
  const { form, updateField, setForm } = useFormContext();
  const dispatch = useDispatch();
  const initialFormState = {
    first_name: { value: user.first_name, valid: true, required: true },
    last_name: { value: user.last_name, valid: true, required: true },
    email: { value: user.email, valid: true, required: true },
    oldPassword: { value: '', valid: false, required: false },
    password: { value: '', valid: false, required: false },
    confirm_password: { value: '', valid: false, required: false },
  };

  useEffect(() => {
    const hasPasswordFields = !!(form.oldPassword?.value || form.password?.value || form.confirm_password?.value);

    setForm((prevForm) => {
      return {
        ...prevForm,
        first_name: { value: user.first_name, valid: true, required: true },
        last_name: { value: user.last_name, valid: true, required: true },
        email: { value: user.email, valid: true, required: true },
        oldPassword: {
          value: form.oldPassword?.value || '',
          valid: !hasPasswordFields || form.oldPassword?.valid,
          required: hasPasswordFields,
        },
        password: {
          value: form.password?.value || '',
          valid: !hasPasswordFields || form.password?.valid,
          required: hasPasswordFields,
        },
        confirm_password: {
          value: form.confirm_password?.value || '',
          valid: !hasPasswordFields || form.password?.value === form.confirm_password?.value,
          required: hasPasswordFields,
        },
      };
    });
    dispatch(clearState());
  }, [
    user,
    form.oldPassword?.value,
    form.oldPassword?.valid,
    form.password?.value,
    form.password?.valid,
    form.confirm_password?.value,
    setForm,
    dispatch,
  ]);

  useEffect(() => {
    if (!user.isVerified) {
      setPasswordEnabled(true);
    }
  }, [user.isVerified]);

  const handleSave = (data: FormData) => {
    dispatch(
      update({
        data: data,
        callback: () => {
          setIsEdit(false);
          setForm({ ...form, ...initialFormState });
        },
      }),
    );
  };

  return (
    <div className="w-full border-t-solid border-t-[1px] border-t-[#000000] flex-1 overflow-y-scroll min-h-[600px]">
      <div
        className={`ml-[34px] mt-[34px] flex flex-col border rounded-[10px] border-[#808080] max-w-[525px] h-full bg-[#F0F0F0] ${isEdit ? 'max-h-[500px]' : 'max-h-[350px]'}`}
      >
        <div className="flex pl-[26px] pr-[28px] pt-[26px] justify-between">
          <h3 className="text-[#0F0F0F] font-poppins font-semibold text-[14px] tracking-[1px]">Personal Information</h3>
          {!isEdit && (
            <Button
              text="Edit"
              color="off-white"
              icon={getCdnUrl('images/edit.svg')}
              iconSize={15}
              flex
              action={() => setIsEdit(true)}
              className="text-[14px] font-poppins gap-x-[5px] font-[500] tracking-[1px] text-[#808080] rounded-[6px] border border-[#808080] py-[6.7px] px-[11px] max-w-[90px]"
            />
          )}
        </div>
        <Form reset={false} onSubmit={handleSave} hasFormData>
          <UserAvatar avatar={form.image?.value || (user.image as FormDataFormField['value'])} isEdit={isEdit} />
          <div className="grid grid-cols-2 grid-flow-col pl-[26px] pt-[26px] mb-[20px] mr-[63px] gap-x-[40px]">
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">First Name</span>
                {!isEdit ? (
                  <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px] max-w-[179px] break-all">
                    {user.first_name}
                  </span>
                ) : (
                  <TextInput
                    name="first_name"
                    value={form?.first_name?.value as string}
                    valid={form?.first_name?.valid}
                    type="text"
                    required
                    className="bg-[#FFFDF8] text-[12px] max-w-[200px]"
                    onChange={(e) => updateField('first_name', e.target.value)}
                    minLimit={3}
                    characterLimit={40}
                  />
                )}
              </div>
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">Email Address</span>
                <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px] max-w-[179px] break-all">
                  {user.email}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">Last Name</span>
                {!isEdit ? (
                  <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px] max-w-[179px] break-all">
                    {user.last_name}
                  </span>
                ) : (
                  <TextInput
                    name="last_name"
                    value={form?.last_name?.value as string}
                    valid={form?.last_name?.valid}
                    type="text"
                    required
                    className="bg-[#FFFDF8] text-[12px] max-w-[200px]"
                    onChange={(e) => updateField('last_name', e.target.value)}
                    minLimit={3}
                    characterLimit={40}
                  />
                )}
              </div>
              <div className="flex flex-col gap-y-2">
                <span className="text-[#808080] font-poppins font-[500] text-[14px] tracking-[1px]">Password</span>
                {!isEdit ? (
                  <span className="text-[#0F0F0F] font-poppins font-[500] text-[14px] tracking-[1px]">
                    {'*'.repeat(8)}
                  </span>
                ) : (
                  <>
                    <PasswordInput
                      name="oldPassword"
                      className="bg-[#FFFDF8] text-[12px] max-w-[200px]"
                      valid={form?.oldPassword?.valid}
                      value={form?.oldPassword?.value as string}
                      required={form.oldPassword?.required}
                      placeholder="Enter Old password"
                      onChange={(e) => updateField('oldPassword', e.target.value)}
                      minLimit={8}
                      characterLimit={40}
                    />
                    <PasswordInput
                      name="password"
                      className="bg-[#FFFDF8] text-[12px] max-w-[200px]"
                      valid={form?.password?.valid}
                      value={form?.password?.value as string}
                      complexPassword
                      required={form.password?.required}
                      placeholder="Enter new password"
                      onChange={(e) => updateField('password', e.target.value)}
                      minLimit={3}
                      characterLimit={40}
                    />
                    <PasswordInput
                      name="confirm_password"
                      className="bg-[#FFFDF8] text-[12px] max-w-[200px]"
                      valid={form?.confirm_password?.valid}
                      value={form?.confirm_password?.value as string}
                      required={form.confirm_password?.required}
                      placeholder="re-enter new password"
                      onChange={(e) => updateField('confirm_password', e.target.value)}
                      minLimit={3}
                      characterLimit={40}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          {isEdit && (
            <div className="flex flex-1 gap-x-4 justify-end mb-[20px] mr-[63px]">
              <Button
                text="Cancel"
                color="off-white"
                border
                className="text-[14px] px-[16px] py-[6px] rounded-[6px] max-w-[130px] max-h-[26.3px] font-poppins"
                flex
                action={() => {
                  setIsEdit(false);
                }}
              />
              <Button
                color="blue"
                text="Save"
                flex
                loading={appState.loading}
                relative
                type="submit"
                className="px-[25px] py-[6px] font-poppins rounded-[6px] max-w-[130px] max-h-[26.3px] text-[14px]"
              />
            </div>
          )}
        </Form>
      </div>
      {passwordEnabled && (
        <ConfirmModal
          message="Reset your password to complete your account registration"
          onConfirm={() => {}}
          onCancel={() => setPasswordEnabled(false)}
          position="middle-center"
          isButtonEnable={false}
        />
      )}
    </div>
  );
};

export default UserInfo;
