import React from 'react';
import { Image } from '../lib';
import { useNavigate } from 'react-router-dom';
import { getCdnUrl } from '../../utility/cdnUtility';
import { useTrackEvent } from '../../config/amplitude';

interface AddCardProps {
  goto?: string;
}

const AddCard: React.FC<AddCardProps> = (props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  return (
    <div
      onClick={() => {
        if (props.goto?.includes('/kitchen')) {
          trackEvent('create-character-profile', { 'create-character-button': 'profile' });
        }

        if (props.goto) {
          navigate(props.goto);
        }
      }}
      className="flex border-2 py-[8px] cursor-pointer border-[#808080] rounded-[10px] justify-between flex-col items-center gap-y-[5px] w-[157px] h-[118px]"
    >
      <div className="flex flex-col flex-1 justify-center items-center gap-y-[15px]">
        <Image src={getCdnUrl('icons/add.svg')} className="w-[32px] h-[32px]" alt="add" />
        <h3 className="font-inter font-semibold text-[14px] text-center">Create New</h3>
      </div>
    </div>
  );
};

export default AddCard;
