const Style = {
  input: 'mb-4 last:mb-0',
  base: `cursor-pointer w-full overflow-hidden relative`,
  select:
    'w-[120%] outline-none border-transparent cursor-pointer px-3 appearance-none bg-inherit items-center flex h-[inherit]',
  success: 'mb-0',
  error: 'text-red-600 !border !border-solid !border-red-500 !bg-red-200',
  errorWrapper: `border-0 before:absolute before:left-0 before:right-0 before:top-0 before:bottom-4 
    before:rounded before:border before:border-solid before:border-red-500 after:-mt-3`,
  warning: 'text-orange-500',
  warningWrapper: `border-0 before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0
    before:rounded before:border before:border-solid before:border-orange-500`,
  message: 'mb-0',
};

export default Style;
