import { FormDataFormField } from '../components/form/formInterfaces';
import DOMPurify from 'dompurify';
import { Temporal } from '@js-temporal/polyfill';
import logger from '../logger';

export const getFileUrl = (file: FormDataFormField['value']): string | undefined => {
  if (file && file instanceof File) {
    const objectUrl = URL.createObjectURL(file);
    return objectUrl;
  } else if (typeof file === 'string') {
    return file;
  }
};

export const formatNumber = (num: number) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num.toString();
};

export const generateHTML = (text: string) => {
  try {
    const lines = text.split('\n');
    const html = lines
      .map((line) => {
        if (line.trim() === '') {
          return '<br>';
        }
        return `<p>${line.trim()}</p>`;
      })
      .join('');

    return DOMPurify.sanitize(html);
  } catch (error) {
    return '';
  }
};

export const getEnvVariable = (name: string) => {
  const env = process.env;
  if (env[name]) {
    return env[name];
  }
  return '';
};

export const getEllipseForFile = (name: string) => {
  return name.slice(0, 8) + '...' + name.split('.')[1];
};

export const dateInDays = (dateInput: Temporal.PlainDateTime | string) => {
  try {
    const date = typeof dateInput === 'string' ? Temporal.PlainDateTime.from(dateInput) : dateInput;
    const today = Temporal.Now.plainDateISO();
    const difference = today.since(date.toPlainDate()).total({ unit: 'days' });

    if (difference == 0) {
      return 'Today';
    } else if (difference <= 10) {
      return `${difference} day${difference > 1 ? 's' : ''} ago`;
    }

    const formattedDate = date.toPlainDate().toString();

    return formattedDate;
  } catch (error) {
    logger.error('Invalid date format');
    return;
  }
};

export const getEscapedName = (name: string) => {
  /* Escapes special regex characters in `name` to safely use in a regex pattern. */
  return name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const wrapCharacterNames = (content: string, characters: string[]): string => {
  const sortedCharacters = [...characters].sort((a, b) => b.length - a.length);

  const segments = content.split(/(@\[@[^)]+\][^)]+\))/g);

  const processUnwrappedText = (text: string) => {
    const namePattern = new RegExp(
      `\\b(${sortedCharacters.map((name) => name.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|')})\\b`,
      'gi',
    );
    return text.replace(namePattern, (match) => `@[@${match}](${match})`);
  };

  return segments
    .map((segment, i) => {
      if (i % 2 === 1) return segment;
      return processUnwrappedText(segment);
    })
    .join('');
};

export const unWrapCharacterNames = (content: string): string => {
  return content.replace(/@\[@([^)\]]+)\]\(\1\)/g, '$1');
};
