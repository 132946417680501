import React from 'react';
import { Button } from '../lib';
import { toast } from 'react-toastify';

const SampleScript: React.FC = () => {
  const sampleScriptFormat = `[
  {
      "first_name": "test",
      "last_name": "Test",
      "email": "test@try-saga.com"
  },
  {
      "first_name": "test2",
      "last_name": "test2",
      "email": "test2@try-saga.com"
  }
]`;

  {
    /* Sample Code Format with Copy Option */
  }
  return (
    <div className="p-4 mr-5 bg-gray-100 border border-dashed border-gray-300 max-h-[360px] rounded-lg w-full lg:w-1/3 mt-8 lg:mt-0">
      <div className="flex justify-between items-center">
        <h4 className="text-base">Sample Script Format</h4>
        <Button
          text="Copy Sample"
          flex
          relative
          className="rounded-lg py-2 px-6 text-sm max-w-[167px] "
          color="blue"
          action={() => {
            navigator.clipboard.writeText(sampleScriptFormat);
            toast.info('Sample Code copied!');
          }}
        />
      </div>
      <pre className="mt-4 p-4 bg-white text-sm rounded-lg overflow-auto">{sampleScriptFormat}</pre>
    </div>
  );
};

export default SampleScript;
