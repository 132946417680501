import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { CreateAsset, GetAsset, setAsset, setAssets, startPoll, TrainAsset } from '../reducers/assets';
import { clearState, setError, startLoading, stopLoading } from '../reducers/app';
import { pollingSaga } from './pollingSaga';
import { getEnvVariable } from '../../utility/helpers';
import { createAssetApi, getAssetsApi, trainAssetApi, fetchCharacterSheet } from '../../config/api/assets';
import { extractAxiosError, handleRequest } from './utils';
import { AxiosResponse } from 'axios';

function* createAsset(action: PayloadAction<CreateAsset>) {
  const data = yield* handleRequest(createAssetApi, undefined, undefined, action.payload);
  if (data) {
    yield put(startPoll({ id: data.data.details.assetId, callback: action.payload.callback }));
  }
}

function* trainAsset(action: PayloadAction<TrainAsset>) {
  const data = yield* handleRequest(trainAssetApi, undefined, undefined, action.payload);
  if (data && action.payload.callback) {
    action.payload.callback();
  }
}

function* getAssets() {
  yield* handleRequest(getAssetsApi, setAssets);
}

function* pollFetchSheet(action: PayloadAction<GetAsset>) {
  try {
    yield put(startLoading());

    const stopCondition = (response: AxiosResponse) => Object.keys(response.data.data).length > 0;

    const REACT_APP_POLLING_INTERVAL = getEnvVariable('REACT_APP_POLLING_INTERVAL');
    const REACT_APP_POLLING_THRESHOLD = getEnvVariable('REACT_APP_POLLING_THRESHOLD');

    yield* pollingSaga(
      () => fetchCharacterSheet(action.payload.id),
      function* (response) {
        yield put(setAsset(response.data));
        yield put(stopLoading());
        yield put(clearState());
        if (action.payload.callback) action.payload.callback(action.payload.id);
      },
      stopCondition,
      REACT_APP_POLLING_INTERVAL ? parseInt(REACT_APP_POLLING_INTERVAL) : undefined,
      REACT_APP_POLLING_THRESHOLD ? parseInt(REACT_APP_POLLING_THRESHOLD) : undefined,
      function* () {
        yield put(setError('Our bad, please try again'));
      },
    );
  } catch (e) {
    yield put(setError(extractAxiosError(e)));
  }
}

export function* assetsSaga() {
  yield takeLatest('assets/createAsset', createAsset);
  yield takeLatest('assets/startPoll', pollFetchSheet);
  yield takeLatest('assets/getAssets', getAssets);
  yield takeLatest('assets/trainAsset', trainAsset);
}
