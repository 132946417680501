import SignIn from '../views/auth';
import Signup from '../views/auth/signup';
import ForgotPassword from '../views/auth/forgot-password';
import ResetPassword from '../views/auth/reset-password';

const Routes = [
  {
    path: '/',
    view: SignIn,
    type: 'open',
  },
  {
    path: '/signup',
    view: Signup,
    type: 'open',
  },
  {
    path: '/forgot-password',
    view: ForgotPassword,
    type: 'open',
  },
  {
    path: '/reset-password/:token',
    view: ResetPassword,
    type: 'open',
  },
  {
    path: '/reset-password',
    view: ResetPassword,
    type: 'open',
  },
];

export default Routes;
