import React, { useEffect, useState } from 'react';
import { Button, Form, Image, Select, Spinner, TextInput } from '../lib';
import { KeyVisual, KeyVisualId, PropertiesType, PropertyTypeEnum } from '@try-saga/storybuilder-iso';
import { saveAs } from 'file-saver';
import axios from '../../config/api/axiosConfig';
import logger from '../../logger';
import ImagePlaceholder from './imagePlaceholder';
import { useDispatch } from 'react-redux';
import { generateKeyVisual } from '../../redux/reducers/scenes';
import { getCdnUrl } from '../../utility/cdnUtility';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useDraggableContext } from '../../context/DraggableContext';
import PromptInput from './promptInput';

interface KeyVisualProps extends KeyVisual {
  index: number;
  sceneIndex: number;
  sceneId?: string;
  handleDeleteScene?: (index: number, sceneIndex: number, id?: KeyVisualId) => void;
}

/* TODO - Depends on Generate API */
const handleDownload = async (keyVisualID?: KeyVisualId) => {
  try {
    const response = await axios.get(`/scene/downloadKeyVisualImage/${keyVisualID}`);
    saveAs(response.data, `${keyVisualID}.jpeg`);
  } catch (error) {
    logger.error(error);
  }
};

const ScriptKeyVisual: React.FC<KeyVisualProps> = (props) => {
  const [form, setForm] = useState<PropertiesType>({});
  const [loading, setLoading] = useState(false);
  const scenes = useSelector((state: RootState) => state.scenes?.scenes);
  const dispatch = useDispatch();
  const {
    provided: { dragHandleProps },
  } = useDraggableContext();

  useEffect(() => {
    const { prompt, setting, shotType } = props.properties || {};
    setForm({
      prompt: { type: PropertyTypeEnum.String, value: prompt?.value },
      setting: { type: PropertyTypeEnum.String, value: setting?.value },
      shotType: { type: PropertyTypeEnum.String, value: shotType?.value },
    });
  }, [props.properties]);

  const handleChange = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: { ...form[name], value: value },
    });
  };

  const handleDelete = () => {
    if (props.handleDeleteScene) {
      props.handleDeleteScene(props.index, props.sceneIndex, props.id);
    }
  };

  const handleGenerate = () => {
    const data = {
      sceneId: props.sceneId,
      order: props.order,
      keyVisualId: props.id,
      keyVisualProperties: form,
      sceneIndex: props.sceneIndex,
    };
    setLoading(true);
    dispatch(
      generateKeyVisual({
        data: data,
        visuals: scenes[props.sceneIndex].keyVisuals,
        callback: () => {
          setLoading(false);
        },
      }),
    );
  };

  return (
    <div
      className="rounded-[10px] p-[1px]"
      style={{
        background: 'linear-gradient(141.55deg, #c6c6c6 0%, #ededed 50%, #c6c6c6 100%)',
        height: 'max-content',
      }}
    >
      <div className="p-[13px] bg-[#F0F0F0] flex flex-col gap-y-[20px] min-w-[442px] rounded-[10px]">
        <div className="relative w-[418px] h-[238px] rounded-[10px] overflow-hidden">
          <div className="absolute left-[9px] top-[7px] z-20 flex justify-center items-center w-[20px] h-[20px] rounded-[5px] bg-[#B3BEBB] text-center">
            <span className="font-inter font-semibold text-[12px]">{props.index + 1}</span>
          </div>
          <div onClick={handleDelete} className="absolute right-[10px] top-[7px] z-20 cursor-pointer">
            <Image src={getCdnUrl('icons/delete.png')} alt="delete" />
          </div>
          {props?.images?.coffee_img?.sha256 && (
            <div
              onClick={() => handleDownload(props.id)}
              className="absolute right-[40px] top-[7px] z-20 cursor-pointer"
            >
              <Image src="https://cdn.try-saga.com/icons/download.svg" alt="download" />
            </div>
          )}
          <div className="flex justify-center w-full h-full">
            {props?.images?.coffee_img?.sha256 ? (
              <Image src={props?.images?.coffee_img?.sha256 as string} alt="scene" />
            ) : loading ? (
              <div className="w-[418px] h-[238px] rounded-[10px] p-[1px] bg-[#DEDEDE] flex">
                <div className="m-auto">
                  <Spinner
                    width={80}
                    height={80}
                    className="rounded-full p-[11.92px] animate-spin-reverse relative spinner"
                    color="conic-gradient(from 180deg at 50% 50%, #6100FF 0deg, #6100FF 63.24deg, rgba(255, 255, 255, 0) 360deg)"
                    backGroundColor="#F0F0F0"
                  />
                </div>
              </div>
            ) : (
              <ImagePlaceholder />
            )}
          </div>
        </div>
        <Form {...dragHandleProps}>
          <div className="bg-[#FFFFFF] gap-y-[7.98px] rounded-[10px] pl-[14px] pr-[16.5px] pt-[12px] pb-[26px] flex flex-col">
            <div className="flex flex-col gap-y-[7.5px]">
              <p className="text-[#7B7D81] font-[400] leading-[15px] text-[12px]">Setting</p>
              <TextInput
                name={`setting_${props.index}`}
                rows={4}
                value={(form?.setting?.value as string) || ''}
                className="bg-[#FAFAFA] rounded-[1px] border border-solid border-[#EDEEEF] text-[12px] font-[300] font-poppins leading-[18px] text-[#111111]"
                onChange={(e) => handleChange(`setting`, e.target.value)}
                type="textarea"
                valid={true}
              />
            </div>
            <div className="flex flex-col gap-y-[7.5px] mt-[10px]">
              <p className="text-[#7B7D81] font-[400] leading-[15px] text-[12px]">PROMPT</p>
              <PromptInput handleChange={handleChange} sceneIndex={props.sceneIndex} properties={props.properties} />
            </div>
            <div className="flex justify-between mt-[12px]">
              <div className="max-w-[131px]">
                <Select
                  unselectText="Shot Type"
                  className="font-poppins h-[28px] min-w-[150px] mt-[5px] text-[12px] font-[500] bg-[#FFFDF8] rounded-[8px] border border-1 border-[#1111111A]"
                  default={form?.shotType?.value as string}
                  options={[
                    { label: 'Close up', value: 'close-up' },
                    { label: 'Extreme close up', value: 'extreme_close_up' },
                    { label: 'Medium close up', value: 'medium close-Up' },
                    { label: 'Medium shot', value: 'medium shot' },
                    { label: 'Wide shot', value: 'wide shot' },
                    { label: 'Arial shot', value: 'arial shot' },
                  ]}
                  name={`shotType_${props.index}`}
                  onChange={(name: string, value: string, valid: boolean) => handleChange('shotType', value)}
                />
              </div>
              <div className="flex items-end">
                <Button
                  text="Generate"
                  loading={loading}
                  disabled={!form.setting?.value || !form.prompt?.value}
                  action={handleGenerate}
                  relative
                  className="rounded-[8px] gap-x-[14px] text-[16px] !w-[166px] h-[28px] py-[5px]"
                  color="blue"
                  flex
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ScriptKeyVisual;
