import React, { useEffect } from 'react';
import ProfileCard from './card';
import AddCard from './addCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { getAssets } from '../../redux/reducers/assets';
import { Asset } from '@try-saga/storybuilder-iso';
import { Spinner } from '../lib';

const Characters = () => {
  const assets = useSelector((state: RootState) => state.assets?.assets);
  const dispatch = useDispatch();
  const app = useSelector((state: RootState) => state.app);

  useEffect(() => {
    dispatch(getAssets());
  }, [dispatch]);

  return (
    <div className="inline-flex gap-x-[23px] flex-wrap pl-[40px] pt-[40px] w-full border-t-[1px] border-t-solid border-t-[#000000] flex-1 overflow-y-scroll">
      {app.loading ? (
        <div className="m-auto">
          <Spinner
            width={80}
            height={80}
            className="rounded-full p-[11.92px] animate-spin-reverse relative spinner"
            color="conic-gradient(from 180deg at 50% 50%, #6100FF 0deg, #6100FF 63.24deg, rgba(255, 255, 255, 0) 360deg)"
            backGroundColor="#F0F0F0"
          />
        </div>
      ) : (
        <>
          {assets != null ? (
            assets.map((asset: Asset, index: number) => {
              return (
                <ProfileCard
                  key={index}
                  image={asset.images?.face_base?.sha256 || asset.images?.front_view?.sha256}
                  title={asset.name}
                  date={asset.createdAt}
                />
              );
            })
          ) : (
            <></>
          )}
          <AddCard goto="/kitchen?tab=new" />
        </>
      )}
    </div>
  );
};

export default Characters;
