import React, { useCallback, useEffect, useState } from 'react';
import ProfileCard from './card';
import AddCard from './addCard';
import { formatNumber } from '../../utility/helpers';
import { AxiosResponse } from 'axios';
import axios from '../../config/api/axiosConfig';
import { Story } from '@try-saga/storybuilder-iso';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { clearState, startLoading, stopLoading } from '../../redux/reducers/app';
import { Spinner } from '../lib';
import logger from '../../logger';

const Animations = () => {
  const [stories, setStories] = useState([]);
  const dispatch = useDispatch();
  const appState = useSelector((state: RootState) => state.app);
  const getStories = useCallback(async () => {
    try {
      dispatch(startLoading());
      const response: AxiosResponse = await axios.get('/stories/getAllStories');
      if (response.status === 200) {
        dispatch(stopLoading());
        setStories(response.data.data);
      }
    } catch (error) {
      dispatch(stopLoading());
      logger.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearState());
    getStories();
  }, [dispatch, getStories]);

  return (
    <div className="inline-flex gap-x-[23px] flex-wrap pl-[40px] pt-[40px] w-full border-t-[1px] border-t-solid border-t-[#000000] flex-1 overflow-y-scroll">
      {appState.loading ? (
        <div className="m-auto">
          <Spinner
            width={80}
            height={80}
            className="rounded-full p-[11.92px] animate-spin-reverse relative spinner"
            color="conic-gradient(from 180deg at 50% 50%, #6100FF 0deg, #6100FF 63.24deg, rgba(255, 255, 255, 0) 360deg)"
            backGroundColor="#F0F0F0"
          />
        </div>
      ) : (
        <>
          {stories.map((story: Story, index) => {
            return (
              <ProfileCard
                length="1:40"
                key={index}
                goto={`/story/${story.id}`}
                title={story.name}
                date={story.createdAt}
                credits={20}
                views={formatNumber(6900)}
              />
            );
          })}
          <AddCard goto="/script" />
        </>
      )}
    </div>
  );
};

export default Animations;
