import NotFoundPage from '../views/404';
import Profile from '../views/auth/profile';
import Home from '../views/home';
import Kitchen from '../views/kitchen';
import NotFound from '../views/notFound';
import Scenes from '../views/scenes';
import UploadScript from '../views/uploadScript';
import SendInvite from '../views/sendInvite';

const Routes = [
  {
    path: '/home',
    view: Home,
    type: 'private',
  },
  {
    path: '/upload-script',
    view: UploadScript,
    type: 'private',
  },
  {
    path: '/profile',
    view: Profile,
    type: 'private',
  },
  {
    path: '/script',
    view: UploadScript,
    type: 'private',
  },
  {
    path: '/story/:id',
    view: Scenes,
    type: 'private',
  },
  {
    path: '/kitchen',
    view: Kitchen,
    type: 'private',
  },
  {
    path: '/sendinvite',
    view: SendInvite,
    type: 'private',
  },
  {
    path: '/404',
    view: NotFoundPage,
    type: 'public',
  },
  {
    path: '*',
    view: NotFound,
    type: 'public',
  },
];

export default Routes;
