import React from 'react';
import { Button } from '../lib';
import { ConfirmModalProps } from './confirmInterface';

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  message,
  onConfirm,
  onCancel,
  position = 'top-center',
  isButtonEnable = true,
  style,
}) => {
  const getPositionStyle = () => {
    switch (position) {
      case 'top-right':
        return 'top-1 right-1';
      case 'middle-center':
        return 'top-1/3 left-1/2 transform -translate-x-1/2 text-center';
      case 'top-center':
      default:
        return '';
    }
  };

  return (
    <div
      className={`fixed z-50 bg-[#F0F0F0] shadow-lg rounded-[20px] p-8 max-w-sm w-full font-[400] text-[12px] leading-[22px] tracking-[1px] text-center border border-gray-200 ${getPositionStyle()}`}
      style={{ position: 'fixed', ...style }}
    >
      <Button
        action={onCancel}
        text="&times;"
        flex
        color="transparent"
        className="absolute top-0 max-w-[10px] right-3 text-[25px] text-gray-500 hover:text-gray-700"
      />

      <p className="font-poppins">{message}</p>
      <div className="flex justify-end mt-4">
        {isButtonEnable && (
          <>
            <Button
              className="text-[16px] max-w-[116px] p-2 rounded-[8px] max-h-[28px] mr-4"
              flex
              text="Cancel"
              action={onCancel}
              fullWidth
            />

            <Button
              text="Confirm"
              border
              flex
              className="text-[16px] !bg-[#0EAA00] p-2 !border-[#0EAA00] rounded-[8px] max-w-[160px] max-h-[28px] h-full"
              action={onConfirm}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmModal;
