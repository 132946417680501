import React, { useEffect, useRef, useState } from 'react';
import { getFeatureFlags } from '../config/launchDarkly';

export const useDarkly = (keys: string[]) => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});
  const prevKeysRef = useRef<string[]>([]);
  useEffect(() => {
    if (JSON.stringify(prevKeysRef.current) !== JSON.stringify(keys)) {
      prevKeysRef.current = keys;
      getFeatureFlags(keys).then(setFlags);
    }
  }, [keys]);
  return flags;
};
