import * as amplitude from '@amplitude/analytics-browser';
import logger from '../logger';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '');

export const useTrackEvent = () => {
  const storedUserEmailId = useSelector((state: RootState) => state.user.email);

  const trackEvent = (eventName: string, eventProperties: Record<string, string> = {}, userEmailId?: string) => {
    const email = userEmailId || storedUserEmailId;

    logger.info(`Tracking event: ${eventName} for user: ${email}`);

    amplitude.track({
      event_type: eventName,
      user_id: email,
      event_properties: eventProperties,
    });
  };

  return { trackEvent };
};
