import React, { useEffect } from 'react';
import CharacterList from './characterList';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { getAssets } from '../../redux/reducers/assets';
import { Spinner } from '../lib';
import { clearState } from '../../redux/reducers/app';

interface CharactersProps {
  handleActiveTabChange: (active: string) => void;
}

const Characters: React.FC<CharactersProps> = ({ handleActiveTabChange }) => {
  const assets = useSelector((state: RootState) => state.assets.assets);
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
    dispatch(getAssets());
  }, [dispatch]);

  return (
    <div className="flex-1 rounded-[8px] bg-[#F0F0F0] gap-y-[32px] flex flex-col px-[44px] max-h-[calc(100vh-150px)] min-h-[calc(100vh-150px)]  overflow-y-scroll relative min-h-[500px]">
      {app.loading ? (
        <div className="m-auto">
          <Spinner
            width={80}
            height={80}
            className="rounded-full p-[11.92px] animate-spin-reverse relative spinner"
            color="conic-gradient(from 180deg at 50% 50%, #6100FF 0deg, #6100FF 63.24deg, rgba(255, 255, 255, 0) 360deg)"
            backGroundColor="#F0F0F0"
          />
        </div>
      ) : assets.length ? (
        <CharacterList title="My Characters" characters={assets} />
      ) : (
        <p className="m-auto">
          No character have been created yet. Click{' '}
          <span className="text-[#11111180] font-[400] cursor-pointer" onClick={() => handleActiveTabChange('new')}>
            &apos;Create New&apos;
          </span>{' '}
          to create a new character.
        </p>
      )}
    </div>
  );
};

export default Characters;
