import React from 'react';
import { DragNDrop } from './dragndrop';
import { DroppableItems, DroppableItemType } from './droppable';

interface ContainerProps {
  items?: DroppableItemType[];
  update: (items: DroppableItemType[] | undefined) => void;
  children: React.ReactNode;
}

export const DragNDropContainer: React.FC<ContainerProps> = ({ items, update, children }) => {
  return (
    <DragNDrop items={items} update={update}>
      <DroppableItems>{children}</DroppableItems>
    </DragNDrop>
  );
};
