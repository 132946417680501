import React from 'react';
import { getCdnUrl } from '../../utility/cdnUtility';
import { Image } from '../lib';

interface ModalProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <div>
          <button onClick={() => onClose(false)} style={modalStyles.closeButton}>
            <Image src={getCdnUrl('icons/x.svg')} alt="close" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const modalStyles: {
  overlay: React.CSSProperties;
  content: React.CSSProperties;
  closeButton: React.CSSProperties;
} = {
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#0000004a',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(1px)',
    zIndex: '30',
  },
  content: {
    background: '#fff',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '80%',
  },
  closeButton: {
    maxWidth: '18px',
    float: 'right',
  },
};
